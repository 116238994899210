import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MesaModel } from 'src/app/model/mesa.model';
import { MesasFirebaseService } from 'src/app/services/mesas-fb.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reservations-menu',
  templateUrl: './reservations-menu.component.html',
  styleUrls: ['./reservations-menu.component.css']
})
export class ReservationsMenuComponent implements OnInit {
  @Output() navToMesas = new EventEmitter<boolean>()
  mesasList:MesaModel[]
  constructor(private mesas: MesasFirebaseService) {

  }

  ngOnInit(): void {
    this.retrieveMesas();
  }

  goMesas() {
    this.navToMesas.emit(true)
  }

  retrieveMesas(): void {
    //Julio Ocampo - me falta revisar si el comercio tiene disponible servicio de mesas
    this.mesas.getMesasByComercio("1").snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() })
        )
      )
    ).subscribe(data => {
      this.mesasList =  data.sort(function (first, second) {
        return first.numMesa - second.numMesa;
      });
      console.log(this.mesasList)
      this.mesasList.forEach( (mesa) => {
        if(mesa.estado == 'En proceso') {
          if(mesa.expireDate != null && mesa.expireDate != undefined) {
            var now = new Date().getTime()
            if(now > mesa.expireDate){
              mesa.estado = 'Disponible'
              mesa.expireDate = null
              this.mesas.update(mesa.key, mesa)
              .then(() => {
                
              })
              .catch(err => {
                console.log(err)
              });
            }
          }
        }
      });
    });
  }
}
