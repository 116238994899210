<app-header></app-header>

<mat-card>
    <mat-card-header>
        <mat-card-subtitle>
            <div align="center" style="padding-bottom: 10px;">
                <label class="confirm-label">{{conceptoText}}</label>
            </div>
        </mat-card-subtitle>
    </mat-card-header>
</mat-card>

<mat-card>
    <div align="center">
        <form [formGroup]="formGroup" class="form">

            <mat-form-field style="width: 60%;">
                <input matInput autofocus formControlName="concepto" [placeholder]="conceptoText">
            </mat-form-field>
            <div class="form-element" align="center" style="margin-top:20px;">
                <button mat-raised-button color="primary" type="submit" [disabled]="!formGroup.valid" class="button"
                    (click)="next()">
                    Continuar
                    <mat-icon *ngIf="isLoading">
                        <mat-spinner color="accent" diameter="20">
                        </mat-spinner>
                    </mat-icon>
                </button>
            </div>
        </form>
    </div>
</mat-card>

<app-footer></app-footer>