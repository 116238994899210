<table align="center" width="100%">
    <tr>
      <td align="center"><img src="assets/images/frame.svg" class="img-thumbnail"></td>
    </tr>
    <tr>
      <td align="center"> <label  style="font-weight: bold;">Página no encontrada</label></td>
    </tr>
    <tr>
      <td align="center"><img src="assets/images/error.svg" class="img-thumbnail"></td>
    </tr>
  </table>