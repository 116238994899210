import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Payment } from 'src/app/model/payment.model';
import { OpenpayService } from 'src/app/services/openpay.service';
import { PaymentService } from 'src/app/store/payment.service';
import html2canvas from 'html2canvas';



@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit, AfterViewInit {

  total: string;
  beneficiary: string;
  accountSource: string;
  accountOrigin: string
  folio: string
  formattedDate: string;

  payment: Payment;

  imageBase64: string

  private paymentServiceSubscription: Subscription | undefined;

  url: any;
  showShared: boolean;


  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private openPayService: OpenpayService,
    private router: Router
  ) {
    const datePipe = new DatePipe('en-US');
    const now = new Date();
    this.formattedDate = datePipe.transform(now, 'yyyy/MM/dd HH:mm:ss');
  }



  ngOnInit() {
    this.total = "0";

    this.payment = JSON.parse(localStorage.getItem("payment"));
    console.log("Payment")
    console.log(JSON.stringify(this.payment))
    let idTransaction = localStorage.getItem("idTransaction")
    this.total = this.payment.amount + "";
    this.beneficiary = this.payment.comercio+""
    this.accountOrigin = 'XXXX ' + this.payment.cardNumber.substring(12);
    //this.accountOrigin = this.payment.account
    this.folio = idTransaction//paymentInfo.folio;

    //localStorage.removeItem("brazalete_info")
    //localStorage.removeItem('payment_info')
    //localStorage.removeItem('brazalete_info')
    localStorage.removeItem('idTransaction')


    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        console.log(JSON.stringify(currentPayment))
        this.payment = currentPayment;
      }
    );





    /*
    
    
    const maskedClabe = 'XXXX ' +  registerUser.clabe.substring(14);
    let json = {folio:"", accountSource:"", accountOrigin:"", beneficiary:"", total:""}
    json.folio=responsePP.message.order_id;
    json.beneficiary=registerUser.nombre;
    json.accountOrigin=maskedClabe;
    json.accountSource=responsePP.message.card.card_number.substr(-8);
    json.total=responsePP.message.amount
    localStorage.setItem("payment_info",JSON.stringify(json))
    */


  }


  ngAfterViewInit() {
    // Código que se ejecutará después de que la vista se haya inicializado completamente
    console.log('La vista ha sido inicializada completamente');
    this.shareTicket()

  }


  goHome() {
    let json = JSON.parse(localStorage.getItem('payment'))
    var ruta = json.idEvent
    this.router.navigate(['/' + ruta]);
  }


  shareTicket() {
    console.log("shareTicket")
    const comprobanteElement = document.getElementById('ticket');
    let url;
    let image;
    html2canvas(comprobanteElement).then((canvas) => {
      // Obtiene la imagen como base64
      const imageBase64 = canvas.toDataURL('image/png');

      // Ahora puedes enviar imageBase64 al servicio que desees
      console.log(imageBase64);
      this.sendTicket(imageBase64)
    });



  }


  sendTicket(image: string) {

    this.openPayService
      .shareTicket(image)
      .subscribe((data) => {
        //alert('RESPONSE OK --> '+JSON.stringify(data))
        console.log('shareTicket==>' + JSON.stringify(data))
        this.url = data.url;
        this.showShared = true;
        console.log(this.showShared)
      },
        (error) => {
          console.log('NOTIFY ERROR')
          console.log(JSON.stringify(error))

        },
        () => {
        });

  }
}
