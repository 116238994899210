import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Payment } from 'src/app/model/payment.model';
import { PaymentService } from 'src/app/store/payment.service';

@Component({
  selector: 'app-nav-component',
  templateUrl: './nav-component.component.html',
  styleUrls: ['./nav-component.component.css']
})
export class NavComponentComponent implements OnInit {
  private paymentServiceSubscription: Subscription | undefined;
  payment: Payment;

  constructor(private router: Router,
    private paymentService: PaymentService,
  ) { }

  ngOnInit() {
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        console.log(JSON.stringify(currentPayment))
        this.payment = currentPayment;
      }
    );
  }

  goHome() {
    let json=JSON.parse(localStorage.getItem('payment'))
    var ruta =  json.idEvent
    this.router.navigate(['/' + ruta]);
  }

  ngOnDestroy(): void {
    this.paymentServiceSubscription.unsubscribe();
  }
}
