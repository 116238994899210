<mat-card>
    <div align="center">
        <form class="form">
            <mat-grid-list cols="1" rowHeight="550">
                <mat-grid-tile>
                    <img srcset="assets/images/conveyor_1_v1.gif 1200w" sizes="(max-height: 400px) 280px,
                        600px" src="assets/images/conveyor_1_v1.gif" />
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </div>
</mat-card>