import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Payment } from 'src/app/model/payment.model';
import { TagsService } from 'src/app/services/tags.service';
import { PaymentService } from 'src/app/store/payment.service';


@Component({
  selector: 'app-folio',
  templateUrl: './folio.component.html',
  styleUrls: ['./folio.component.css']
})
export class FolioComponent implements OnInit {

  isLoading: boolean;
  private errorServiceSubscription: Subscription | undefined;
  private paymentServiceSubscription: Subscription | undefined;
  formGroup: FormGroup;

  conceptoText: string;
  payment: Payment;
  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.createForm();
    this.conceptoText = 'Concepto'
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        if (currentPayment) {
          this.payment = currentPayment;
          if (this.payment.transactionType) {
            this.conceptoText = this.payment.transactionType;
          } 
        }
      }
    );
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'concepto': [null, [Validators.required]]
    })

  }

  ngOnDestroy(): void {
    let errorMessage: Error[] = [];
    this.paymentServiceSubscription.unsubscribe();


  }
  next() {
    //let route = '/recharge/credit-card'
    this.payment.description = this.formGroup.controls['concepto'].value
    this.paymentService.setCurrentPayment(this.payment);

    let route = 'recharge/amount'
    this.router.navigate([route]);

  }
}
