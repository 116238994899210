import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';

import {
  Observable,
  throwError as observableThrowError,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': 'qrUMluWeckIh7AS3Siua6HnPkllxAeB7hS2z48Oh'

  }),
};

@Injectable({
  providedIn: 'root'
})
export class OpenpayService {
  private handleError: HandleError;


  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler,) {
    this.handleError = httpErrorHandler.createHandleError('HeroesService');

   }



  getDetailTransaction(id:String): Observable<any> {
    let restBaseUrl=`${environment.urlOpenPay}${id}`
    console.log(restBaseUrl)
    return this.http
      .get<any>(restBaseUrl, httpOptions)
      .pipe(catchError(this.handleError('getDetailTransaction-->')));
  }


  shareTicket(imageBase64:String): Observable<any> {
    let restBaseUrl=`${environment.shareFileURL}shareTicket`
    const generatedUuid = uuidv4();

    let httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': 'opiJHy6JJP7OuWpWHJrdy9lO1gvrppmA9itXNBu9'
    
      }),
    };
    
    var payload = {"qr-name":generatedUuid, "qr":imageBase64}

    return this.http
    .post<any>(restBaseUrl, payload, httpHeaders)
    .pipe(catchError(this.handleError('shareTicket', payload)));
    
  }
}
