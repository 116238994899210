import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {
  Observable,
  throwError as observableThrowError,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Notify } from '../model/notify';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
     'Authorization': 'Basic ' + btoa(environment.authUser+':'+environment.authPass)

  }),
};

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private handleError: HandleError;


  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler,
    @Inject('BRAZALETES_BASE_URL') private brazaletesBaseURL: string) {
    this.handleError = httpErrorHandler.createHandleError('Payment');
  }


  // URL to web API
  private restBaseUrl = 'processor/notify';
  sendingNotify(payload: Notify, isGift:boolean, isFlowKeeppay:boolean): Observable<Notify> {
    let baseTemp = this.brazaletesBaseURL;
    let urlBaseFinal = baseTemp + this.restBaseUrl
    return this.http
      .post<Notify>(urlBaseFinal, payload, httpOptions)
      .pipe(catchError(this.handleError('sendingNotify', payload)));
  }
}
