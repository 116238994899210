import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { map, timer, takeWhile, finalize } from 'rxjs';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.css']
})
export class CountDownComponent {
  @Input() seconds = 300;
  count = this.seconds;
  timeRemaining$ = timer(0, 1000).pipe(
    map(n => {
      this.count = (this.seconds - n) * 1000 
      return (this.seconds - n) * 1000}
    ),
    takeWhile(n => n >= 0),
    finalize(() => {
      if(this.count == -1000) {
        window.location.reload();
      }
    })
  )
}