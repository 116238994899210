<app-header></app-header>

<mat-card>
    <div align="center">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="form">
            <div *ngIf="submitted">
                <mat-card>
                    <div align="center">
                        <mat-grid-list cols="1" rowHeight="550">
                            <mat-grid-tile>
                                <img srcset="assets/images/conveyor_1_v1.gif 1200w" sizes="(max-height: 400px) 280px,
                                        600px" src="assets/images/conveyor_1_v1.gif" />
                            </mat-grid-tile>
                        </mat-grid-list>

                        <mat-grid-list cols="1" rowHeight="40">
                            <mat-grid-tile>
                                <label  style="padding-bottom: 25px;" class="procesando">Estamos procesando tu pago...</label>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </mat-card>

            </div>

            <div *ngIf="!submitted">

                <mat-card>
                    <mat-card-header>
                        <mat-card-subtitle>
                            <div align="center" style="padding-bottom: 10px;">
                                <label class="confirm-label">Ingrese el código de seguridad</label>
                            </div>
                        </mat-card-subtitle>
                    </mat-card-header>
                </mat-card>


                <mat-form-field class="example-full-width" style="width:30%;">
                    <input autofocus matInput [maxLength]="maxlength" formControlName="cvv"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        style="border-color: #41C5F9;margin-right: 15px;background-color:none !important; text-align: center;"
                        onkeypress="soloNumeros(event)" onblur="javascript:verificarNumerosChange(this);">
                </mat-form-field>

                <p style="text-align:center;">CVV</p>

                <div class="form-element" align="center">
                    <button mat-raised-button color="primary" type="submit" class="button"
                        [disabled]="!formGroup.valid">Continuar</button>
                </div>



            </div>

        </form>
    </div>
</mat-card>

<app-footer></app-footer>