export class Payment {
    idTag: string;
    idEvent: string;
    userName: string;
    userEmail: string;
    userPhone: string;
    userCP: string;
    cardNumber: string;
    expireDate: string;
    card: string;
    account: string;
    description: string;
    amount: number;
    comision: number;
    platformAmount: number;
    total: number;
    street:string;
    city:string;
    town:string;
    image:string;
    clabe:String
    cvv:String;
    isFlowKeeppay:boolean;
    isEquestre:number;
    comercio:String;
    transactionType:string;

    constructor(
        /*idTag: string,
        idEvent: string,
        userName: string,
        userEmail: string,
        userPhone: string,
        userCP: string,
        cardNumber: string,
        expireDate: string,
        card: string,
        account: string,
        description: string,
        amount: number,
        comision: number,
        platformAmount: number,
        total: number*/) {
        this.isFlowKeeppay=false;
        this.isEquestre=0;
    }

}
