<mat-grid-list cols="12" rowHeight="30px">
    <mat-grid-tile colspan="12" rowspan="4">
        <img width="80" height="80" src="assets/images/keeppay.png">
    </mat-grid-tile>
    <mat-grid-tile colspan="12">
        <label class="h2Extended">Elige las mesas a reservar</label>
    </mat-grid-tile>
    <mat-grid-tile colspan="12">
            Tiempo restante: <app-count-down></app-count-down>
    </mat-grid-tile>
    <mat-grid-tile colspan="12">
        Mesas seleccionadas: {{mesasSeleccionadas}}
    </mat-grid-tile>
    <mat-grid-tile colspan="12">
        Total a pagar: {{totalPagar}}
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
        <button mat-mini-fab class="mesa-disponible-example" disabled></button>
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
        <button mat-mini-fab class="mesa-no-disponible-example" disabled></button>
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
        <button mat-mini-fab class="mesa-seleccionado-example" disabled></button>
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
        Disponible
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
        No disponible
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
        Seleccionado
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="14" class="pista-background">
        <h1 class="text-pistas" style="height: 400px;">Pista 1</h1>
    </mat-grid-tile>
    <mat-grid-tile colspan="8" rowspan="14" style="overflow-y: scroll;">
        <div class="internalMatGrid">
            <mat-grid-list cols="2" rowHeight="2300px">
                <mat-grid-tile>
                    <div class="internalMatGrid" style="margin-top: 950px;">
                        <mat-grid-list cols="1" rowHeight="60px">
                            <div *ngFor="let mesas of mesasList | slice:0:22; let i = index ">
                                <mat-grid-tile>
                                    <div *ngIf="mesasListSelected.length > 0">
                                        <div *ngIf="searchMesaSelected(mesas.numMesa)">
                                            <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)"  
                                                class="mesa-seleccionado">
                                                {{mesas.numMesa}}
                                            </button>
                                        </div>
                                        <div *ngIf="!searchMesaSelected(mesas.numMesa)">
                                            <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)" 
                                                [disabled]="mesas.estado != 'Disponible'" 
                                                [ngClass]="mesas.estado == 'Disponible' ? 'mesa-disponible' : 'mesa-no-disponible'">
                                                {{mesas.numMesa}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="mesasListSelected.length == 0">
                                        <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)" 
                                            [disabled]="mesas.estado != 'Disponible'" 
                                            [ngClass]="mesas.estado == 'Disponible' ? 'mesa-disponible' : 'mesa-no-disponible'">
                                            {{mesas.numMesa}}
                                        </button>
                                    </div>
                                </mat-grid-tile>
                            </div>
                        </mat-grid-list>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile>
                    <div class="internalMatGrid" style="margin-top: 950px;">
                        <mat-grid-list cols="1" rowHeight="60px">
                            <div *ngFor="let mesas of mesasList | slice:22:44; let i = index ">
                                <mat-grid-tile>
                                    <div *ngIf="mesasListSelected.length > 0">
                                        <div *ngIf="searchMesaSelected(mesas.numMesa)">
                                            <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)"  
                                                [ngClass]="'mesa-seleccionado'">
                                                {{mesas.numMesa}}
                                            </button>
                                        </div>
                                        <div *ngIf="!searchMesaSelected(mesas.numMesa)">
                                            <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)" 
                                                [disabled]="mesas.estado != 'Disponible'" 
                                                [ngClass]="mesas.estado == 'Disponible' ? 'mesa-disponible' : 'mesa-no-disponible'">
                                                {{mesas.numMesa}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="mesasListSelected.length == 0">
                                        <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)" 
                                            [disabled]="mesas.estado != 'Disponible'" 
                                            [ngClass]="mesas.estado == 'Disponible' ? 'mesa-disponible' : 'mesa-no-disponible'">
                                            {{mesas.numMesa}}
                                        </button>
                                    </div>
                                </mat-grid-tile>
                            </div>
                        </mat-grid-list>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="14" class="pista-background">
        <h1 class="text-pistas" style="height: 400px;">Pista 2</h1>
    </mat-grid-tile>
    <mat-grid-tile colspan="12" rowspan="3">
        <button mat-raised-button="" color="primary" type="button" [disabled]="mesasListSelected.length == 0 ? true : false"
            (click)="next()"
            class="mat-focus-indicator button mat-raised-button mat-button-base mat-primary">
            <span class="mat-button-wrapper">Continuar</span>
            <span matripple="" class="mat-ripple mat-button-ripple"></span>
            <span class="mat-button-focus-overlay"></span>
        </button>
    </mat-grid-tile>
</mat-grid-list>

<!--<div class="container-fluid text-center">
    <br>
    <div class="row justify-content-center">
        <div class="col-md-6 col-sm-12 col-xs-12 col-12">
            <div class="row align-items-center">
                <div class="col-md-2 col-sm-3 col-xs-2 col-2 text-bg-secondary text-center">
                    <h1 class="text-pistas" style="height: 400px;">Pista 1</h1>
                </div>
                <div class="col-md-8 col-sm-6 col-xs-8 col-8">
                    <div class="row justify-content-center" style="max-height: 400px; overflow-y: scroll;">
                        <div class="col-md-6 col-sm-6 col-xs-6 col-6">
                            <div class="d-grid gap-4">
                                <div *ngFor="let mesas of mesasList | slice:0:22; let i = index ">
                                    <div *ngIf="mesasListSelected.length > 0">
                                        <div *ngIf="searchMesaSelected(mesas.numMesa)">
                                            <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)"  
                                                [ngClass]="'btn btn-lg btn-success'">
                                                {{mesas.numMesa}}
                                            </button>
                                        </div>
                                        <div *ngIf="!searchMesaSelected(mesas.numMesa)">
                                            <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)" 
                                                [disabled]="mesas.estado != 'Disponible'" 
                                                [ngClass]="mesas.estado == 'Disponible' ? 'btn btn-lg btn-secondary' : 'btn btn-lg btn-danger'">
                                                {{mesas.numMesa}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="mesasListSelected.length == 0">
                                        <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)" 
                                            [disabled]="mesas.estado != 'Disponible'" 
                                            [ngClass]="mesas.estado == 'Disponible' ? 'btn btn-lg btn-secondary' : 'btn btn-lg btn-danger'">
                                            {{mesas.numMesa}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 col-6">
                            <div class="d-grid gap-4">
                                <div *ngFor="let mesas of mesasList | slice:22:44; let i = index ">
                                    <div *ngIf="mesasListSelected.length > 0">
                                        <div *ngIf="searchMesaSelected(mesas.numMesa)">
                                            <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)" 
                                                [ngClass]="'btn btn-lg btn-success'">
                                                {{mesas.numMesa}}
                                            </button>
                                        </div>
                                        <div *ngIf="!searchMesaSelected(mesas.numMesa)">
                                            <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)" 
                                                [disabled]="mesas.estado != 'Disponible'" 
                                                [ngClass]="mesas.estado == 'Disponible' ? 'btn btn-lg btn-secondary' : 'btn btn-lg btn-danger'">
                                                {{mesas.numMesa}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="mesasListSelected.length == 0">
                                        <button id="{{mesas.numMesa}}" (click)="clickSelectMesa(mesas.numMesa)" 
                                            [disabled]="mesas.estado != 'Disponible'" 
                                            [ngClass]="mesas.estado == 'Disponible' ? 'btn btn-lg btn-secondary' : 'btn btn-lg btn-danger'">
                                            {{mesas.numMesa}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-sm-3 col-xs-2 col-2 text-bg-secondary text-center">
                    <h1 class="text-pistas" style="height: 400px;">Pista 2</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-md-center justify-content-sm-center">
        <div class="col-md-auto col-sm-auto mt-5">
            <button mat-raised-button="" color="primary" type="button" [disabled]="mesasListSelected.length == 0 ? true : false"
                (click)="next()"
                class="mat-focus-indicator button mat-raised-button mat-button-base mat-primary">
                <span class="mat-button-wrapper">Continuar</span>
                <span matripple="" class="mat-ripple mat-button-ripple"></span>
                <span class="mat-button-focus-overlay"></span>
            </button>
        </div>
    </div>
</div>-->
