<div class="fullscreen-div" style="height: 100%;">
    <div id="ticket" style="background: #1D87FC;">

        <mat-grid-list cols="1" rowHeight="80" style="margin-top: 20px;">
            <mat-grid-tile>
                <img src="assets/images/frame.svg" width="80" height="80">
            </mat-grid-tile>
        </mat-grid-list>


        <mat-grid-list cols="1" rowHeight="55px" style="margin-top: 20px;">
            <mat-grid-tile>
                <label class="title_1">¡KeepCash exitoso! </label>
            </mat-grid-tile>

            <mat-grid-tile>
                <p class="title_1">{{ total | currency:'USD':'symbol':'1.2-2' }}</p>
            </mat-grid-tile>
        </mat-grid-list>




        <mat-grid-list cols="1" rowHeight="25px" style="margin-bottom:20px">
            <mat-grid-tile>
                <label class="title_2">Destinatario: </label>
            </mat-grid-tile>

            <mat-grid-tile>
                <label class="title_3">{{beneficiary}}</label>
            </mat-grid-tile>
        </mat-grid-list>


        <mat-grid-list cols="1" rowHeight="25px" style="margin-bottom:20px">

            <mat-grid-tile>
                <label class="title_2">Cuenta Origen: </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="title_3">{{accountSource}} </label>
            </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="1" rowHeight="25px" style="margin-bottom:20px">

            <mat-grid-tile>
                <label class="title_2">Fecha de pago: </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="title_3">{{formattedDate}}</label>
            </mat-grid-tile>


        </mat-grid-list>

        <mat-grid-list cols="1" rowHeight="45px" style="margin-bottom:20px">

            <mat-grid-tile>
                <label class="title_4">Folio: </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="title_4">{{folio}}</label>
            </mat-grid-tile>


        </mat-grid-list>
    </div>


    <mat-grid-list cols="1" rowHeight="45px" style="margin-bottom:80px;">

        <mat-grid-tile>

            <share-buttons
                [include]="['copy', 'facebook', 'email',  'sms', 'telegram', 'whatsapp']"
                [showIcon]="true" [showText]="false" [url]="url" description="Compartir">
            </share-buttons>


        </mat-grid-tile>

    </mat-grid-list>


    <mat-grid-list cols="1" rowHeight="25px" style="margin-bottom:20px;bottom: 0;">

        <mat-grid-tile>
            <p class="title_4">Solicita tu factura en:</p>     <a mat-button href="https://www.google.com/" target="_blank" style="color: white;">wwww.2keepcash.com/facturacion</a>
        </mat-grid-tile>


    </mat-grid-list>


    <!--

    <div class="container_header">
  
  
    </div>
  
    <app-footer></app-footer>
    <div class="container">
  
      <table align="center" width="100%">
  
  
        <tr>
          <td align="center">
            <span class="failed">Pago exitoso</span>
  
          </td>
  
        </tr>
        <tr>
            <td align="center"><label for="cvv" style="font-weight: bold;"> Recibiras tu comprobante por correo electrónico
            </label></td>
        </tr>
        <tr>
          <td style="height: 30%;">&nbsp;</td>
        </tr>
  
  
  
      </table>
    </div>
  
    <table align="center" width="100%">
      <tr>
        <td align="center"><img src="assets/images/success.png" alt="Imagen" class="bottom-image">
        </td>
      </tr>
    </table>
  
    <table align="center" width="100%">
      <tr>
        <td align="center">
          
        </td>
      </tr>
    </table>
  
  
  </div>
-->

    <!--
<app-header></app-header>

<table align="center" width="100%">
    <tr>
        <td>
            <app-nav-component></app-nav-component>
        </td>
    </tr>
    <tr>
        <td align="center"><label for="cvv" style="font-weight: bold;">Pago exitoso</label></td>
    </tr>
    <tr>
        <td align="center"><label for="cvv" style="font-weight: bold;"> Recibiras tu comprobante por correo electrónico
        </label></td>
    </tr>

    <tr>
        <td align="center"><img src="assets/images/success.svg" class="img-thumbnail"></td>
    </tr>
</table>

<app-footer></app-footer>-->