import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';  
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IpServiceService {

  private handleError: HandleError;


  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('HeroesService');
  }

  ipURL = "https://api.ipify.org/?format=json";

  public getIPAddress()  
  {  
    return this.http.get<any>(this.ipURL)
      .pipe(
        catchError(this.handleError('getIPAddress'))
      );
  }  
}
