<app-header></app-header>

<mat-card>
    <mat-card-header>
        <mat-card-subtitle>
            <div align="center"><label class="confirm-label" style="padding-bottom: 10;">Indica el monto</label></div>
        </mat-card-subtitle>
    </mat-card-header>
</mat-card>

<mat-card>
    <div align="center">
        <form [formGroup]="formGroup" class="form">

            <mat-form-field class="form-element" >
                <input matInput type="text"
                    #amount
                    formControlName="amount"
                    inputPrice
                    (change)="calculate()"
                    />
                <mat-error *ngIf="!formGroup.controls['amount'].valid && formGroup.controls['amount'].touched">
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-element">
                <input matInput autofocus formControlName="concepto" [placeholder]="conceptoText">
            </mat-form-field>
            
            <mat-grid-list cols="2" rowHeight="100px" *ngIf="isEquestre">
                <mat-form-field >
                    <mat-label>Concepto</mat-label>
                    <mat-select formControlName="conceptosControl" required  (selectionChange)="getConcept($event.value)">
                      <mat-option>--</mat-option>
                      <mat-option *ngFor="let c of conceptos" [value]="c">
                        {{c.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formGroup.controls['conceptosControl'].hasError('required')">Por favor escoge un concepto</mat-error>
                  </mat-form-field>
            </mat-grid-list>

            <mat-divider ></mat-divider >
            <mat-grid-list cols="2" rowHeight="40px" >
                <mat-grid-tile>
                    <span>Total</span>
                </mat-grid-tile>
                <mat-grid-tile>
                    <span>{{subtotal | currency: '$'}}</span>
                </mat-grid-tile>
                <mat-grid-tile *ngIf="false">
                    <label>{{tituloComision}}</label>
                </mat-grid-tile>
                <mat-grid-tile *ngIf="false">
                    <label style="font-weight: bold;"> {{total | currency: '$'}}</label>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-divider ></mat-divider>

            <!--mat-list>
            <mat-divider></mat-divider>
            <mat-list-item *ngFor="let row of commissionsCustoms">
                <div mat-line>
                        <p style="float:left">Cargo en tarjetas {{row.cardType}}</p>
                        <p style="float:right">$0.00</p>
                </div>
                <div mat-line>
                    <p>Comisión del {{row.commission}} %</p>
                </div>
               
                <mat-divider></mat-divider>
            </mat-list-item>
           
            
        </mat-list-->

            <div class="form-element" align="center" style="padding-top: 50px;">
                <button mat-raised-button color="primary" (click)="onSubmit()" type="submit" class="button"
                    [disabled]="usAmount!= '' && usAmount!= '0.00' ">Continuar
                    <mat-icon *ngIf="isLoading">
                        <mat-spinner color="accent" diameter="20">
                        </mat-spinner>
                    </mat-icon>
                </button>
            </div>
        </form>
    </div>
</mat-card>

<app-footer></app-footer>