import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AMEXService } from 'src/app/services/amex-services';
import { NotifyService } from 'src/app/services/notify.service';
import { PaymentService } from 'src/app/store/payment.service'; 
import { environment } from 'src/environments/environment';
import { Notify, NotifyData } from 'src/app/model/notify';
import { Payment } from 'src/app/model/payment.model';
import { Transaction } from 'src/app/model/transaction.model';
import { Router } from '@angular/router';
import { Error } from 'src/app/model/error.model';

declare var Checkout: any;
declare var amexFormInitValues:any

@Component({
  selector: 'app-amex',
  templateUrl: './amex.component.html',
  styleUrls: ['./amex.component.css']
})
export class AmexComponent implements OnInit {
  message: string = '';
  submitted = false;
  withError = false;

  idOrder = '';
  idTransaction = '';
  successIndicator = '';
  token = '';
  isGift: boolean;
  private paymentServiceSubscription: Subscription | undefined;
  private transactionServiceSubscription: Subscription | undefined;
  paymentSesion: Payment;
  transaction: Transaction;
  constructor(private notifyService: NotifyService,
    private amexService: AMEXService,
    private paymentService: PaymentService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    console.log("amexUrl: " + environment.amexUrl)
    console.log("amexUsername: " + environment.amexUsername)
    console.log("amexMerchant: " + environment.amexMerchant)

    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        this.paymentSesion = currentPayment;
      }
    );
    this.transactionServiceSubscription = this.paymentService.currentTransaction.subscribe(
      currentTransaction => {
        this.transaction = currentTransaction;
        console.log(JSON.stringify(this.transaction))
        this.initCheckout()
      }
    );

  }

  initCheckout() {
    //function amexFormInitValues(sessionID, idOrder, idTransaction, succesIndicator, isGift) {
    /*  document.getElementById('token_span').innerHTML = sessionID
      document.getElementById('idorder_span').innerHTML = idOrder
      document.getElementById('idtransaction_span').innerHTML = idTransaction
      document.getElementById('isGift').innerHTML = isGift
    */

    amexFormInitValues(this.transaction.token, this.transaction.succesIndicator )

  }

  onSubmit() {
    Checkout.showEmbeddedPage('#embed-target')
  }

  getOrder() {
    this.idOrder = this.transaction.idOrder//document.getElementById('idorder_span')?.innerHTML || '';
    this.amexService.getOrder(this.idOrder)
      .subscribe((data) => {
        console.log("RESPONSE_GET_ORDER")
        console.log(JSON.stringify(data))
        let transactions = data.transaction
        let lengthTransaction = transactions.length;
        let getCurentTransaction = transactions[lengthTransaction - 1];
        console.log("=== --> " + getCurentTransaction)
        this.loadDetailTransaction(getCurentTransaction.transaction.id);
      },
        (error) => {
          this.goError('Ocurrió un error al obtener la ordern', '003');
          this.router.navigate(['response/error']);
        },
        () => {

        });
  }

  goError(error: string, code: string) {
    let errorMessage: Error[] = [];
    errorMessage.push(new Error(error, code))
    this.paymentService.setCurrentError(errorMessage)
    this.router.navigate(['response/error']);
  }

  loadDetailTransaction(idTransaction: string) {
    //this.idOrder = this.transaction.idOrder; //document.getElementById('idorder_span')?.innerHTML || '';
    //this.idTransaction = document.getElementById('idtransaction_span')?.innerHTML || '';
    //this.successIndicator = this.transaction.succesIndicator;// document.getElementById('succesIndicator_span')?.innerHTML || '';
    //this.token = this.transaction.token// document.getElementById('token_span')?.innerHTML || '';
    //this.isGift = false//JSON.parse(document.getElementById('isGift')?.innerHTML || '');
    //let idTransactionFix = '1'
    this.amexService.getDetailTransaction(this.transaction.idOrder, idTransaction)
      .subscribe((data) => {
        console.log(JSON.stringify(data))
        this.sendNotify(
          data
        );
      },
        (error) => {

          this.sendNotify(
            error
          );
        },
        () => {

        });
  }

  sendNotify( response: any) {
    console.log(JSON.stringify(this.transaction))
    console.log('send Notify token='+ this.transaction.token + ', idTransaction='+this.transaction.idTransaction );
    let url = 'response/error'
    let notify_request = this.getPayload(this.transaction.token, this.transaction.idTransaction, response);
    console.log(JSON.stringify(notify_request));
    this.notifyService
      .sendingNotify(notify_request, this.isGift, this.paymentSesion.isFlowKeeppay)
      .subscribe((data) => {
        console.log(data)
        url = 'response/success'
        this.router.navigate([url]);
      },
        (error) => {
          console.log("ERROR NOTIFY")
          console.log(error)
          this.goError('Ocurrió un error al enviar al notify el pago', '003');

        },
        () => {
          // console.log('redirect --->' + url)
          // this.router.navigate([url]);
        });
  }

  getPayload(token: string, idTransaction: string, response: any): Notify {
    let notifyData: NotifyData = new NotifyData(token, idTransaction, response);
    let notify: Notify = new Notify(notifyData);
    return notify;
  }

  ngOnDestroy(): void {
    this.paymentServiceSubscription.unsubscribe();
    this.transactionServiceSubscription.unsubscribe();
  }
}
