<app-header></app-header>

<mat-card>
    <div align="center">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="form">

            <div *ngIf="submitted"  class="fullscreen-div">
                <div class="fullscreen-div" style="height: 100%;">
                    <div class="container_header">
                  
                    </div>
                    <app-footer></app-footer>
                    <div class="container">
                  
                      <table align="center" width="100%">
                        <tr>
                          <td align="center">
                            <span class="failed">Estamos procesando tu pago</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="height: 30%;">&nbsp;</td>
                        </tr>
                      </table>
                    </div>
                  
                    <table align="center" width="100%">
                      <tr>
                        <td align="center"><img src="assets/images/Capa_1.png" alt="Imagen" class="bottom-image">
                        </td>
                      </tr>
                    </table>
                  
                  </div>
            </div>

            <div *ngIf="!submitted">

                <mat-card>
                    <mat-card-header>
                        <mat-card-subtitle>
                            <div align="center"><label class="h2ExtendedCVV">Ingrese el codigo de seguridad</label>
                            </div>
                        </mat-card-subtitle>
                    </mat-card-header>
                </mat-card>


                <mat-form-field class="example-full-width" style="width:30%;">
                    <input autofocus matInput [maxLength]="maxlength" formControlName="cvv"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        style="border-color: #41C5F9;margin-right: 15px;background-color:none !important;"
                        onkeypress="soloNumeros(event)" onblur="javascript:verificarNumerosChange(this);">
                </mat-form-field>

                <p style="text-align:center;">CVV</p>

                <div class="form-element" align="center">
                    <button mat-raised-button color="primary" type="submit" class="button"
                        [disabled]="!formGroup.valid">Continuar</button>
                </div>


                <mat-grid-list cols="2">
                    <mat-grid-tile>
                        <div class="logo">Transacciones realizadas vía:</div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="shield">Tus pagos se realizan de forma segura con encriptación de 256 bits</div>                    </mat-grid-tile>
                </mat-grid-list>



            </div>

        </form>
    </div>
</mat-card>

<app-footer></app-footer>