import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {
  Observable,
  throwError as observableThrowError,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Notify } from '../model/notify';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(environment.amexUsername+':'+environment.amexPassword)

  }),
};

@Injectable({
  providedIn: 'root',
})
export class AMEXService {
  private handleError: HandleError;

  private baseUrl:string='';
  private merchantID:string='';

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler,
    @Inject('BASE_AMEX_URL') private baseUrlParam: string,
    @Inject('AMEX_MERCHANT') private merchant: string) {
    this.baseUrl=baseUrlParam;
    this.merchantID = merchant
    this.handleError = httpErrorHandler.createHandleError('HeroesService');
  }


  // URL to web API
  //private restBaseUrl = 'processor/notify';
  getDetailTransaction( orderid:String,  transactionid:String): Observable<any> {
    let restBaseUrl=`${this.baseUrl}api/rest/version/64/merchant/${this.merchantID}/order/${orderid}/transaction/${transactionid}`
    console.log(restBaseUrl)
    return this.http
      .get<any>(restBaseUrl, httpOptions)
      .pipe(catchError(this.handleError('getDetailTransaction-->')));
  }

  //https://gateway-na.americanexpress.com/api/rest/version/64/merchant/TEST8482158268/order/a51e0864-100d-477a-97ed-104b52bc6bfe
  getOrder(orderid:String): Observable<any>{
    let restBaseUrl=`${this.baseUrl}api/rest/version/64/merchant/${this.merchantID}/order/${orderid}`
    console.log(restBaseUrl)
    return this.http
      .get<any>(restBaseUrl, httpOptions)
      .pipe(catchError(this.handleError('getDetailTransaction-->')));
  }
}
