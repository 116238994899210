<app-header *ngIf="isLoadingComissions"></app-header>

<div align="center"  style="padding-bottom: 10px;padding-top: 10px;"><label class="confirm-label">Agrega una tarjeta de crédito</label></div>


    <div align="center" >
        <form [formGroup]="formGroup"  (ngSubmit)="onSubmit(formGroup.value)" >

        <mat-form-field class="form-element">
            <input matInput autofocus placeholder="Nombre Completo" formControlName="name">
            <mat-error *ngIf="!formGroup.controls['name'].valid && formGroup.controls['name'].touched">
                {{ getErrorName() }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="form-element">
            <input matInput placeholder="Correo electrónico" formControlName="email">
            <mat-error *ngIf="!formGroup.controls['email'].valid && formGroup.controls['email'].touched">
                {{ getErrorEmail() }}
            </mat-error>
        </mat-form-field>

        <!--<mat-form-field class="form-element" fxFlexFill>
            <input matInput placeholder="Dirección"  maxlength="100" formControlName="street"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
            <mat-error *ngIf="!formGroup.controls['street'].valid && formGroup.controls['street'].touched">
                {{ getErrorAddress() }}
            </mat-error>
        </mat-form-field>

        mat-grid-list cols="2">
            <mat-grid-tile
                <mat-form-field class="form-element" fxFlexFill>
                    <input matInput placeholder="Concepto"  maxlength="50" formControlName="concept"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                    <mat-error *ngIf="!formGroup.controls['concept'].valid && formGroup.controls['concept'].touched">
                        {{ getErrorConcept() }}
                    </mat-error>
                </mat-form-field>
            /mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field class="form-element" fxFlexFill>
                    <input matInput placeholder="Cuenta" type="number" maxlength="50" formControlName="account"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                    <mat-error *ngIf="!formGroup.controls['account'].valid && formGroup.controls['account'].touched">
                        {{ getErrorAccount() }}
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile
        </mat-grid-list>-->

        <!--mat-form-field class="form-element" fxFlexFill>
            <input matInput placeholder="Ciudad" maxlength="50" formControlName="city"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
            <mat-error *ngIf="!formGroup.controls['city'].valid && formGroup.controls['city'].touched">
                {{ getErrorCity() }}
            </mat-error>
        </mat-form-field-->
        <mat-form-field class="form-element">
            <input matInput placeholder="Teléfono" type="number" maxlength="10" formControlName="phone"
                oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);">
            <mat-error *ngIf="!formGroup.controls['phone'].valid && formGroup.controls['phone'].touched">
                {{ getErrorPhone() }}
            </mat-error>
        </mat-form-field>
       
        <mat-form-field class="form-element">
            <input matInput maxlength="16" placeholder="Ingresa el número de tu tarjeta" onkeypress="soloNumeros(event)"
                onblur="javascript:verificarNumerosChange(this);" formControlName="cardNumber" (change)="getCardType()"
                oninput="javascript: if (this.value.length > 16) this.value = this.value.slice(0, 16);">
            <mat-error *ngIf="!formGroup.controls['cardNumber'].valid && formGroup.controls['cardNumber'].touched">
                {{ getErrorCardNumber() }}
            </mat-error>
            <mat-error *ngIf="!isValidCardType && formGroup.controls['cardNumber'].touched">
                La tarjeta no es una tarjeta válida
            </mat-error>
        </mat-form-field>
      

        <mat-grid-list cols="2" rowHeight="80px" style="width: 90%;">
            <mat-grid-tile>
                <mat-form-field fxFlexFill>
                    <mat-label>Mes de vencimiento</mat-label>
                    <mat-select placeholder="Mes de vencimiento" formControlName="month" required>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let month of months" [value]="month">
                            {{month}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!formGroup.controls['month'].valid && formGroup.controls['month'].touched">
                        {{ getErrorMonth() }}
                    </mat-error>
                </mat-form-field>
                <!--mat-form-field class="form-element">
                    <input matInput type="number" maxlength="2" placeholder="Mes de vencimiento" formControlName="month"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                    <mat-hint>Mes</mat-hint>
                   
                </mat-form-field-->
            </mat-grid-tile>

            <mat-grid-tile>
                <mat-form-field>
                    <mat-label>Año de vencimiento</mat-label>
                    <mat-select placeholder="Año de vencimiento" formControlName="year" required>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let year of years" [value]="year">
                            {{year}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!formGroup.controls['year'].valid && formGroup.controls['year'].touched">
                        {{ getErrorYear() }}
                    </mat-error>
                </mat-form-field>

            </mat-grid-tile>

            <!--mat-grid-tile>
                <mat-form-field class="form-element">
                    <input matInput type="number" maxlength="2" placeholder="Año de vencimiento" formControlName="year"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                    <mat-hint>Año</mat-hint>
                    <mat-error *ngIf="!formGroup.controls['year'].valid && formGroup.controls['year'].touched">
                        {{ getErrorYear() }}
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile-->
        </mat-grid-list>

        <div class="form-element" align="center" style="margin-top:20px;">
            <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!formGroup.valid"
                (click)="nextAmount()">
                Continuar
                <mat-icon *ngIf="isLoading">
                    <mat-spinner color="accent" diameter="20">
                    </mat-spinner>
                </mat-icon>
            </button>
        </div>


        </form>

    </div>


<app-footer *ngIf="isLoadingComissions"></app-footer>