import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Commission } from 'src/app/model/commission.model';
import { Payment } from 'src/app/model/payment.model';
import { PaymentService } from 'src/app/store/payment.service'; 
import { map } from 'rxjs/operators';
import { LocalService } from 'src/app/services/local-service';
import { CommissionKeeppayService } from 'src/app/services/commission_keeppay.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.css']
})
export class CreditCardComponent implements OnInit {
  
  post: any = '';
 

  isLoadingComissions:boolean;

  commissions: Commission[];
  isNewFlow =false;
  commissionsCustoms: Commission[];

  payment: Payment;
  isValidCardType: boolean;
  isLoading: boolean;

  months: string[]
  years: number[]
  conceptoText: string;
  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
  numberReguex: RegExp = /[0-9]$/

  private paymentServiceSubscription: Subscription | undefined;
  private commissionServiceSubscription: Subscription | undefined;

  public maxlength: number = 16;;


  public formGroup :any
  
  constructor(private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router,
    private commissionService: CommissionKeeppayService,
    private localServices:LocalService) {
      this.formGroup = this.formBuilder.group({
        'name': [null, Validators.required],
        //'city': [null, Validators.required],
        'email': [null, [Validators.required, Validators.pattern(this.emailregex)]],
        'phone': [null, [Validators.required, Validators.pattern(this.numberReguex), Validators.minLength(10), Validators.maxLength(10)]],
        //'cp': [null, [Validators.required, Validators.pattern(this.numberReguex), Validators.minLength(5), Validators.maxLength(5)]],
        'cardNumber': [null, [Validators.required, Validators.pattern(this.numberReguex)]],
        'month': [null, [Validators.required]],
        'year': [null, [Validators.required]],
        'validate': ''
      });
     }

  ngOnInit() {
    this.isLoadingComissions=false;
    this.commissions = [];
    this.commissionsCustoms = [];
    this.maxlength = 16;
    this.conceptoText = 'Concepto'
    this.months = []
    this.years = []
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        if(currentPayment){
          this.payment = currentPayment;
          if (this.payment.transactionType) {
            this.conceptoText = this.payment.transactionType;
          } 
          this.retrieveCommissions();
        }
      }
    );
    //this.setChangeValidate();
    

    
  }

  createForm() {
    

   
    
  }

  getCardType() {
    let creditCard = this.creditCardType(this.formGroup.controls['cardNumber'].value);
    console.log("creditCard" + this.formGroup.controls['cardNumber'].value)
    if (creditCard == null) {
      console.log("The card is invalid")
      this.isValidCardType = false;
      return;
    } else {
      this.isValidCardType = true;
      let numberReguex: RegExp = /[0-9]$/

      this.formGroup.get('cardNumber').setValidators([Validators.required, Validators.pattern(numberReguex)]);
      this.formGroup.get('cardNumber').updateValueAndValidity();
      
    }
    console.log('MAXLENGTH -->' + this.maxlength)
  }

  nextAmount() {
    let creditCard = this.creditCardType(this.formGroup.controls['cardNumber'].value);
    console.log("creditCard")
    if (creditCard == null) {
      console.log("The card is invalid")
      this.isValidCardType = false;
      return;
    }
    this.isValidCardType = true;
    this.payment.userName = this.formGroup.controls['name'].value;
    this.payment.userEmail = this.formGroup.controls['email'].value;
    this.payment.userPhone = String(this.formGroup.controls['phone'].value);
    this.payment.userCP = '01376'//String(this.formGroup.controls.cp.value);
    this.payment.cardNumber = String(this.formGroup.controls['cardNumber'].value);  
    this.payment.expireDate = this.formGroup.controls['month'].value + "/" + (this.formGroup.controls['year'].value - 2000);
    this.payment.card = this.creditCardType(this.payment.cardNumber);
    this.payment.account = this.payment.idTag;//this.formGroup.controls.account.value;//  "0011223344";
    console.log("<-------ddddd------->")
    console.log(this.payment.idTag);
    console.log("<-------ddddd------->")
    
    this.payment.card = creditCard;
    this.payment.town = 'Alvaro Obregon'//this.formGroup.controls.town.value;
    this.payment.street = 'Domicilio conocido'//this.formGroup.controls.street.value;
    this.payment.city = 'Santa Fe'//this.formGroup.controls.city.value;
    //let  description= this.payment.isFlowKeeppay ? 'Keeppay':"BRAZALETES";
    //this.payment.description = this.payment.transactionType;//this.formGroup.controls.concept.value;//'Recarga Brazalete'
    this.paymentService.setCurrentPayment(this.payment);
    //
    let commission = this.getCommissionByCardType();
    this.payment.comision = commission.commission;
      this.payment.platformAmount = commission.userPlatform;
    this.paymentService.setCurrentCommission(commission);
    this.localServices.saveData("payment",JSON.stringify(this.payment));
    this.router.navigate(['recharge/confirm']);      

  }

  retrieveCommissions(): void {
    this.commissionService.getAll().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() })
        )
        
      )
    ).subscribe(data => {
      this.commissions = data;
      this.initCustomCommission();
      console.log("adadasdsadassdasdsdasdasasd");
      this.init();
    });
  }
  init(){
    this.isValidCardType = false;
    this.initMonthsYears();
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        this.payment = currentPayment;
        this.createForm();
        if(!this.payment.isFlowKeeppay){
          if(this.payment.clabe == null && this.localServices.getData("payment")!=undefined&&this.localServices.getData("payment")!=null){
          /* var json =JSON.parse(this.localServices.getData("payment")) 
           console.log(json);
           this.payment.clabe = json.clabe;
           this.payment.userName =json.userName;
           this.payment.userPhone = json.userPhone;
           this.payment.userEmail = json.userEmail;
           this.payment.expireDate = json.expireDate;
           this.payment.cardNumber = json.cardNumber;
           this.payment.card = json.card;
           this.payment.clabe = json.clabe;
           this.formGroup.controls['name'].patchValue(this.payment.userName)
           this.formGroup.controls['phone'].patchValue(this.payment.userPhone)
           this.formGroup.controls['email'].patchValue(this.payment.userEmail)
           this.formGroup.controls['cardNumber'].patchValue(this.payment.cardNumber);
           this.formGroup.controls['month'].patchValue(this.payment.expireDate.substring(0, this.payment.expireDate.indexOf("/"),)) 
           var valorYear:number =parseInt(20+this.payment.expireDate.substring(this.payment.expireDate.indexOf("/")+1, this.payment.expireDate.length));
           this.formGroup.controls['year'].patchValue(valorYear) */

          }
        }else{
          if(this.payment.card == null && this.localServices.getData("payment")!=undefined&&this.localServices.getData("payment")!=null){
          /*   var json = JSON.parse(this.localServices.getData("payment")) 
            console.log(json);
            this.payment.clabe = json.clabe;
            this.payment.userName =json.userName;
            this.payment.userPhone = json.userPhone;
            this.payment.userEmail = json.userEmail;
            this.payment.expireDate = json.expireDate;
            this.payment.cardNumber = json.cardNumber;
            this.payment.card = json.card;
            
            this.payment.clabe = json.clabe;
            this.formGroup.controls['name'].patchValue(this.payment.userName)
            this.formGroup.controls['phone'].patchValue(this.payment.userPhone)
            this.formGroup.controls['email'].patchValue(this.payment.userEmail)
            this.formGroup.controls['cardNumber'].patchValue(this.payment.cardNumber);
            this.formGroup.controls['month'].patchValue(this.payment.expireDate.substring(0, this.payment.expireDate.indexOf("/"),))
            var valorYear:number =parseInt(20+this.payment.expireDate.substring(this.payment.expireDate.indexOf("/")+1, this.payment.expireDate.length));
            console.log(valorYear);
            this.formGroup.controls['year'].patchValue(valorYear)  */
          }
        }
        this.isLoadingComissions=true;
      }
    );
    //this.maxlength = 16;

  }

  initCustomCommission() {
    this.commissionsCustoms = [];
    let amexCommission: any = this.commissions.filter((commission) => {
      return commission.plan === 1 && commission.cardType === 'AMEX';
    });
    let amexC = Object.assign({}, amexCommission[0])
    this.commissionsCustoms.push(amexC);

    let visaCommission: any = this.commissions.filter((commission) => {
      return commission.plan === 1 && commission.cardType.indexOf('VISA') != -1;
    });
    let visaC = Object.assign({}, visaCommission[0])
    visaC.cardType = 'VISA';
    this.commissionsCustoms.push(visaC);

    let masterCardCommission: any = this.commissions.filter((commission) => {
      return commission.plan === 1 && commission.cardType.indexOf('MASTERCARD') != -1;
    });
    let mastercardC = Object.assign({}, masterCardCommission[0])
    mastercardC.cardType = 'MASTERCARD'
    this.commissionsCustoms.push(mastercardC);

  }


  getCommissionByCardType(): Commission {
    let commisionResponse = null;
    for (var commission of this.commissions) {
      if (commission.cardType.indexOf(this.payment.card) != -1) {
        commisionResponse = commission;
        break;
      }
    }
    console.log("getCommissionByCardType")
    console.log(commisionResponse)
    return commisionResponse;
  }

  creditCardType(cc: any) {
    console.log('creditCardType -->' + cc)
    let cardType = null;
    let amex = new RegExp('^3[47][0-9]{13}$');
    let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
    let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
    let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');
  
    let mastercard = new RegExp('^5[1-5][0-9]{14}$');
    let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
  
    let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
    let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
    let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');
    
    let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
    let jcb =  new RegExp('^35[0-9]{14}[0-9]*$');
  
  
    if (visa.test(cc)) {
      this.maxlength = 16;
      cardType = 'VISA'
    }
    
    console.log("CARD ==> " +cc)
    console.log('amex card == '+ amex.test(cc))
    if (amex.test(cc)) {
      console.log('is AMEX')
      this.maxlength = 15;
      cardType = 'AMEX'

    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
      this.maxlength = 16;
      cardType = 'MASTERCARD'
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
      return 'DISCOVER';
    }
    if (diners.test(cc)) {
      return 'DINERS';
    }
    if (jcb.test(cc)) {
      return 'JCB';
    }
    if (cup1.test(cc) || cup2.test(cc)) {
      return 'CHINA_UNION_PAY';
    }
    return cardType;
  }
  
  getErrorTown(){
    return this.formGroup.get('town').hasError('required') ? 'La delegación es requerida' :
      this.formGroup.get('town').hasError('pattern') ? 'No es una delegación válida' : '';
  }

  setChangeValidate() {
    this.formGroup.get('validate').valueChanges.subscribe(
      (validate) => {
        if (validate == '1') {
          this.formGroup.get('name').setValidators([Validators.required, Validators.minLength(3)]);
        } else {
          this.formGroup.get('name').setValidators(Validators.required);
        }
        this.formGroup.get('name').updateValueAndValidity();
      }
    )
  }

  get name() {
    return this.formGroup.get('name') as FormControl
  }

/*  checkPassword(control) {
    let enteredPassword = control.value
    let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
  }*/

  checkInUseEmail(control:any) {
    // mimic http database access
    let db = ['tony@gmail.com'];
    return new Observable(observer => {
      setTimeout(() => {
        let result = (db.indexOf(control.value) !== -1) ? { 'alreadyInUse': true } : null;
        observer.next(result);
        observer.complete();
      }, 4000)
    })
  }


  getErrorName() {
    return this.formGroup.get('name').hasError('required') ? 'El nombre es requerido' :
      this.formGroup.get('name').hasError('pattern') ? 'No es un nombre válido' : '';
  }

  getErrorConcept() {
    return this.formGroup.get('concept').hasError('required') ? 'El concepto es requerido' :
      this.formGroup.get('concept').hasError('pattern') ? 'No es un concepto válido' : '';
  }

  getErrorAccount() {
    return this.formGroup.get('account').hasError('required') ? 'La cuenta es requerida' :
      this.formGroup.get('account').hasError('pattern') ? 'No es una cuenta válida' : '';
  }

  getErrorEmail() {
    return this.formGroup.get('email').hasError('required') ? 'El correo eléctronico es requerido' :
      this.formGroup.get('email').hasError('pattern') ? 'No es un correo eléctronico válido' : '';
  }

  getErrorAddress() {
    return this.formGroup.get('street').hasError('required') ? 'La dirección es requerida' :
      this.formGroup.get('street').hasError('pattern') ? 'No es una dirección válida' : '';
  }

  getErrorCity() {
    return this.formGroup.get('city').hasError('required') ? 'La ciudad es requerida' :
      this.formGroup.get('city').hasError('pattern') ? 'No es una ciudad válida' : '';
  }

  getErrorCp() {
    return this.formGroup.get('cp').hasError('required') ? 'El Código Póstal es requerido' :
      this.formGroup.get('cp').hasError('pattern') ? 'CP necesita al menos 5 carácteres numéricos' : '';
  }

  getErrorPhone() {
    return this.formGroup.get('phone').hasError('required') ? 'El número de teléfono es requerido' :
      this.formGroup.get('phone').hasError('pattern') ? 'No es un número de teléfono válido' : '';
  }

  getErrorCardNumber() {
    return this.formGroup.get('cardNumber').hasError('required') ? 'El número de tarjeta es requerido' :
      this.formGroup.get('cardNumber').hasError('pattern') ? 'No es una tarjeta válida' : '';
  }

  getErrorMonth() {
    console.log(this.formGroup)
    return this.formGroup.get('month').hasError('required') ? 'El mes de vencimiento es requerido' :
      this.formGroup.get('month').hasError('pattern') ? 'No es un mes válido' : '';
  }

  getErrorYear() {
    return this.formGroup.get('year').hasError('required') ? 'El año de vencimiento es requerido' :
      this.formGroup.get('year').hasError('pattern') ? 'No es un año válido' : 
      this.formGroup.get('year').hasError('yearIncorrect') ? 'No es un año válido' : ''
      ;
  }

  onSubmit(post:any) {
    this.post = post;
  }

  ngOnDestroy(): void {
    this.paymentServiceSubscription.unsubscribe();
  }

  initMonthsYears(){
    this.months = ["01","02","03","04","05","06","07","08","09","10","11","12"]

    const now = new Date();
    const currentYear = now.getFullYear();
    console.log(currentYear)
    let yearMax = currentYear +10
    for(let i=currentYear; i<=yearMax;i++){
      this.years.push(i)
    }
  }

}
