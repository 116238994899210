import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Payment } from 'src/app/model/payment.model';
import { PaymentService } from 'src/app/store/payment.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent implements OnInit {
  private paymentServiceSubscription: Subscription | undefined;
  payment: Payment;
  isFlowKeeppay:boolean=false;
  constructor(private paymentService: PaymentService) { }

  ngOnInit(): void {
    this.isFlowKeeppay=false;
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        this.payment = currentPayment;
        this.isFlowKeeppay=this.payment.isFlowKeeppay;
      }
    );
  }

}
