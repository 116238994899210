import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Commission } from 'src/app/model/commission.model';
import { Payment } from 'src/app/model/payment.model';
import { PaymentService } from 'src/app/store/payment.service';


interface Concepto {
  name: string;
  id: number;
  amount:number;
}

@Component({
  selector: 'app-recarga-amount',
  templateUrl: './recarga-amount.component.html',
  styleUrls: ['./recarga-amount.component.css']
})
export class RecargaAmountComponent implements OnInit {
  formGroup: FormGroup =this.formBuilder.group({   });
  conceptoText:string;
  payment: Payment;

  isLoading: boolean;

  total: number;
  @ViewChild('amount') amountInput:MatInput;
  isLoadingCommissions: boolean;
  tituloComision= "Cargo por uso de la plataforma"

  private paymentServiceSubscription: Subscription | undefined;
  private commisionServiceSubscription: Subscription | undefined;
  public usAmount = "0.00";
  commission: Commission;
  isFlowKeeppay: boolean;
  isEquestre:boolean=false;
  tipTotal:number;
  tipType:number;
  subtotal:number=0;

  conceptos: Concepto[] = [
    {name: '$1,800  - 1 Caballeriza', id: 1, amount:1800},
    {name: '$5,400  - 3 Caballerizas', id: 1, amount:5400},
    {name: '$7,200  - 4 Caballerizas', id: 1, amount:7200},
    {name: '$9,000  - 5 Caballerizas', id: 1, amount:9000},
    {name: '$1,800  - TACK', id: 2, amount:1800},
    {name: '$24,000 - Inscripcion Equipo', id: 3, amount:24000},
    {name: '$6,000  -  sInscripcion Individual', id: 3, amount:6000},

  ];  
  numberReguex: RegExp = /^\d+(\.\d{1,2})?$/

  constructor(private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router) {
      this.conceptoText='Concepto:'
     }

  ngOnInit() {
    this.isEquestre=false;
    this.tipTotal=0;
    this.tipType=0;
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        this.payment = currentPayment;
        this.isFlowKeeppay =this.payment.isFlowKeeppay;
        if(this.payment.transactionType){
          this.conceptoText = this.payment.transactionType
        }
        if(this.isFlowKeeppay){
          this.tituloComision = "Seguridad antifraude"
          this.isEquestre=this.payment.isEquestre==1?true:false;
        }
        this.createForm();
      }
    );

    this.commisionServiceSubscription = this.paymentService.currentCommision.subscribe(
      currentCommision => {
        this.commission = currentCommision;
      }
    );
  }

  setAmount(amount: number) {
    this.formGroup.patchValue({
      amount: amount,
    } ,{ emitEvent: true });
    this.amountInput.value=amount
    this.calculate();
  }

  calculate() {
    
    if(this.commission.cardType==undefined){
      this.commission = {
      cardType: "VISA/MASTERCARD",
      commission: 4.84,
      plan:1,
      surateWithIVA:"4.58",
      totalUserCharge:9.42,
      userPlatform:8
      }
    }
    console.log(!this.payment.isFlowKeeppay)
    /*if(!this.payment.isFlowKeeppay){
      //let tempAmount = (this.payment.comision / 100) + 1
      let tempAmount = +this.usAmount + this.commission.userPlatform;
      this.total= 0;//Math.round((tempAmount + Number.EPSILON) * 100) / 100;
    }else{*/

    //let tempAmount: number = (this.commission.commission / 100) + 1
    console.log(this.usAmount)
    //console.log(tempAmount)

     let tempAmount = ((+this.formGroup.controls['amount'].value)*(1+(this.tipTotal/100))) //+ this.commission.userPlatform;
      this.subtotal = Math.round((tempAmount + Number.EPSILON) * 100) / 100
    /*}*/
    
  }

  createForm() {
    let numberReguex: RegExp = /^\d+(\.\d{1,2})?$/
    if(this.payment.isEquestre==1){
      this.formGroup = this.formBuilder.group({
        'amount': ["0.00", [Validators.required, Validators.pattern(numberReguex)]],
        'conceptosControl': [null],
        'concepto': [null, []],
        'validate': ''
      });

    }else{
      this.formGroup = this.formBuilder.group({
        'amount': ["0.00", [Validators.required, Validators.pattern(numberReguex)]],
        'concepto': [null, []],
        'validate': ''
      });
    }

    
  }


  getConcept(event:any){
    console.log(this.formGroup.controls['conceptosControl']);
    this.formGroup.patchValue({amount: this.formGroup.controls['conceptosControl'].value.amount},{ emitEvent: true })
    this.calculate();
  }

  onSubmit() {
    this.isLoading = true;
    this.payment.amount = this.subtotal;//+this.formGroup.controls['amount'].value
    /*if(!this.payment.isFlowKeeppay){
      this.payment.comision = 0;
      this.payment.platformAmount = this.commission.userPlatform;
      //let tempAmount = (this.payment.comision / 100) + 1
      let tempAmount = this.payment.amount + this.commission.userPlatform;
      this.payment.total = Math.round((tempAmount + Number.EPSILON) * 100) / 100;
    }else{*/
      this.payment.description = this.formGroup.controls['concepto'].value;
      this.payment.comision = this.commission.commission;
      this.payment.platformAmount = this.commission.userPlatform;
      let tempAmount = (this.payment.comision / 100) + 1
      tempAmount = (this.payment.amount * tempAmount) + this.commission.userPlatform;
      this.payment.total = Math.round((tempAmount + Number.EPSILON) * 100) / 100;
    /*}*/
    
    this.paymentService.setCurrentPayment(this.payment);
    this.isLoading = false;
    this.router.navigate(['recharge/credit-card']);
  }

  ngOnDestroy(): void {
    this.paymentServiceSubscription.unsubscribe();
    this.commisionServiceSubscription.unsubscribe;
  }
  
  updateUSAmount(event:any) {
    this.usAmount = event.target.value;
    this.calculate();
  }

  setTip(propina: number) {
    this.tipType=propina;
    if(propina == -1){
      this.formGroup.addControl('tip',new FormControl('', [Validators.required, Validators.pattern(this.numberReguex)]))
    }else{
      this.formGroup.removeControl('tip')
      this.tipTotal= propina;//this.formGroup.controls.amount.value
    }

    this.calculate();
  }

  changeTip(){
    this.tipTotal = this.formGroup.controls['tip'].value;
    this.calculate();
  }

}
