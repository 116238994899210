import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
import { MesaModel } from '../model/mesa.model';
@Injectable({
  providedIn: 'root'
})
export class MesasFirebaseService {
  private dbPath = '/comercios_mesas';
  mesasRef: AngularFireList<MesaModel> = null;
  
  firebaseMesas: AngularFireDatabase;

  constructor(private db: AngularFireDatabase) {
    console.log(" creating tables -->>>> ")
    this.firebaseMesas =db;
    
  }

  getMesasByComercio(idComercio:string){
    this.mesasRef = this.firebaseMesas.list(this.dbPath+"/"+ idComercio);
    return this.mesasRef;
  }
  
  /*getAll(): AngularFireList<MesaModel> {
    return this.mesasRef.query.;
  }*/


  update(key: string, value: any): Promise<void> {
    console.log('UPDATE MESA' +JSON.stringify(value) )
    return this.mesasRef.update(key, value);
  }
  
}