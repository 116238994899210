import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Payment } from 'src/app/model/payment.model';
import { Transaction } from 'src/app/model/transaction.model';
import { NotifyService } from 'src/app/services/notify.service';
import { TagsService } from 'src/app/services/tags.service';
import { Error } from 'src/app/model/error.model';
import { Payment3D } from 'src/app/model/payment3d.model';
import { Notify, NotifyData } from 'src/app/model/notify';
import { PaymentService } from 'src/app/store/payment.service';

@Component({
  selector: 'app-openpay',
  templateUrl: './openpay.component.html',
  styleUrls: ['./openpay.component.css']
})
export class OpenpayComponent implements OnInit {
  formGroup: FormGroup;
  message: string = '';
  isGift: boolean = false;

  name: string;
  card: string
  expire_month: string;
  expire_year: string
  cvv: string
  numberReguex: RegExp = /[0-9]$/

  submitted = false;
  withError = false;
  maxlength: number = 4;

  payment: Payment3D = new Payment3D();

  paymentSesion: Payment;
  transaction: Transaction;
  private paymentServiceSubscription: Subscription | undefined;
  private transactionServiceSubscription: Subscription | undefined;
  errors: Error[];
  private errorServiceSubscription: Subscription | undefined;


  constructor(private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private tagService: TagsService,
    private notifyService: NotifyService,
    private router: Router) {
    // this.formGroup = this.formBuilder.group({
    //   'name': [null, Validators.required],
    //   'card': [null, [Validators.required, Validators.pattern(this.numberReguex), Validators.minLength(15), Validators.maxLength(18)]],
    //   'month': [null, [Validators.required, Validators.pattern(this.numberReguex), Validators.minLength(2), Validators.maxLength(2)]],
    //   'year': [null, [Validators.required, Validators.pattern(this.numberReguex), Validators.minLength(2), Validators.maxLength(2)]],
    //   'cvv': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]

    // });

  }

  ngOnInit(): void {
    this.submitted  = false
    this.createForm();
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        this.paymentSesion = currentPayment;
        //this.verificaCVV();
      }
    );
    this.transactionServiceSubscription = this.paymentService.currentTransaction.subscribe(
      currentTransaction => {
        this.transaction = currentTransaction;
      }
    );

    this.errorServiceSubscription = this.paymentService.currentError.subscribe(
      currentError => {
        this.errors = currentError;
      }
    );
    this.errors = [];
    this.paymentService.setCurrentError(this.errors)
  }

  createForm() {
    let numberReguex: RegExp = /[0-9]$/
    this.formGroup = this.formBuilder.group({
      'cvv': [null, [Validators.required, Validators.pattern(numberReguex)]],
      'validate': ''
    });
  }

  ngOnDestroy(): void {
    this.paymentServiceSubscription.unsubscribe();
    this.transactionServiceSubscription.unsubscribe();
  }

  onSubmit() {
    this.validForm();
    if (!this.withError) {
      this.sendPaymentProcessor();
    }

  }

  validForm() {
    this.withError = false;
    let amount: any = this.paymentSesion.amount;
    this.payment.name = this.paymentSesion.userName;
    this.payment.email = this.paymentSesion.userEmail;
    this.payment.phone = this.paymentSesion.userPhone;
    this.payment.street = this.paymentSesion.street;
    this.payment.city = this.paymentSesion.city;
    this.payment.zipcode = this.paymentSesion.userCP
    this.payment.town = this.paymentSesion.town;//"town city"
    this.payment.number = this.paymentSesion.cardNumber
    let monthindex = this.paymentSesion.expireDate.indexOf("/");
    this.payment.expiry_month = this.paymentSesion.expireDate.substring(0, monthindex);
    this.payment.expiry_year = this.paymentSesion.expireDate.substring(monthindex + 1, this.paymentSesion.expireDate.length);
    this.payment.amount = Number(Math.round(amount * 100) / 100);
    this.payment.token = this.transaction.token;
    this.payment.idTransaction = this.transaction.idTransaction
    this.paymentSesion.cvv = this.formGroup.controls['cvv'].value
    console.log()
    this.message = '';
    this.isGift = false;

    if (amount <= 0) {
      this.message += '<li>Amount is required </li>';
    }
    if (this.payment.name.trim() == '') {
      this.message += '<li>Name  is required </li>';
    }

    if (this.payment.email.trim() == '') {
      this.message += '<li>Email is required </li>';
    }

    if (!this.payment.phone) {
      this.message += '<li>Phone is required </li>';
    }
    if (this.payment.street.trim() == '') {
      this.message += '<li>Street is required </li>';
    }
    if (this.payment.city.trim() == '') {
      this.message += '<li>City is required</li>';
    }
    if (!this.payment.zipcode) {
      this.message += '<li>Zipcode is required </li>';
    }
    if (this.payment.town.trim() == '') {
      this.message += '<li>Town is required </li>';
    }
    if (!this.payment.number) {
      this.message += '<li>Card number is required </li>';
    }

    if (!this.payment.expiry_month) {
      this.message += '<li>Expiry month is required </li>';
    }

    if (!this.payment.expiry_year) {
      this.message += '<li>Expiry year is required </li>';
    }

    if (this.message != '') {
      this.withError = true;
      this.message = '<ul>' + this.message + '</ul>';
    }

    console.log(this.message)
    console.log(this.withError)
    return this.withError;
  }

  sendPaymentProcessor() {
    //console.log(this.paymentSesion);
    //console.log(this.paymentSesion.isFlowKeeppay);
    this.submitted = true
    this.tagService.payment(this.paymentSesion, true)
      .subscribe((data: any) => {

        if(data === null){
          console.log(data)
          this.goError('Ocurrió un error al procesar el pago', '500');

          //this.router.navigate(['response/error']);
        }else{
          console.log(JSON.stringify(data))
          localStorage.setItem("ppResponse", JSON.stringify(data)) 
          let callback = JSON.parse(data.result.urlCallback)
          console.log(callback)
          console.log(callback.payment_method.url)
          window.location.replace(callback.payment_method.url);
        }

      },
        (error) => {
          //console.log('RESPONSE ERROR XXXXX ' + JSON.stringify(error))
          console.error(JSON.stringify(error))
          this.goError('Ocurrió un error al procesar el pago', '500');
          this.router.navigate(['response/error']);
        },
        () => {
         
        });

  }

  goError(error: string, code: string) {
    let errorMessage: Error[] = [];
    errorMessage.push(new Error(error, code))
    this.paymentService.setCurrentError(errorMessage)
    this.router.navigate(['response/error']);
  }


  sendNotify(token: string, idTransaction: string, response: any, req_response: string) {
    console.log('send Notify');
    let notify_request = this.getPayload(token, idTransaction, response);
    console.log(JSON.stringify(notify_request));
    this.notifyService
      .sendingNotify(notify_request, this.isGift, this.paymentSesion.isFlowKeeppay)
      .subscribe((data) => {

      },
        (error) => {
          this.goError('Payment has been declined', '500');
          console.log('RESPONSE ERROR XXXXX ' + JSON.stringify(error))
          console.log(req_response);
          //req_response=req_response.replace("/","-")
          this.router.navigate([req_response]);
        },
        () => {
          this.submitted = false;
          console.log(req_response);
          //req_response=req_response.replace("/","-")
          this.router.navigate([req_response]);
        });
  }

  getPayload(token: string, idTransaction: string, response: any): Notify {
    let notifyData: NotifyData = new NotifyData(token, idTransaction, response);
    let notify: Notify = new Notify(notifyData);
    return notify;
  }




}
