import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MesaModel } from 'src/app/model/mesa.model';
import { Payment } from 'src/app/model/payment.model';
import { MesasFirebaseService } from 'src/app/services/mesas-fb.service';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PaymentService } from 'src/app/store/payment.service';

@Component({
  selector: 'app-reservations-mesas',
  templateUrl: './reservations-mesas.component.html',
  styleUrls: ['./reservations-mesas.component.css']
})
export class ReservationsMesasComponent implements OnInit {
  
  @Input() payment = new Payment
  mesasList:MesaModel[]
  mesasListSelected: MesaModel[]
  mesasSeleccionadas = ""
  totalPagar = "$0.00"
  expireDate: number
  private paymentServiceSubscription: Subscription | undefined;

  constructor(private route: ActivatedRoute, private router: Router, private mesas: MesasFirebaseService,
    private paymentService: PaymentService) {
      var now = new Date()
      now.setMinutes(now.getMinutes() + 5) // timestamp
      now = new Date(now); // Date object
      this.expireDate = now.getTime()
  }

  ngOnInit(): void {
    this.retrieveMesas();
    this.mesasListSelected = []
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        if(currentPayment){
          this.payment = currentPayment;
        }
      }
    );
  }

  clickSelectMesa(numMesaSelected: number) {
    const mesa = this.mesasList.find(({ numMesa }) => numMesa == numMesaSelected);
    const mesaSelected = this.mesasListSelected.findIndex(({ numMesa }) => numMesa == numMesaSelected);
    if(mesaSelected >= 0) {
      mesa.estado = 'Disponible'
      mesa.expireDate = null
      this.mesas.update(mesa.key, mesa)
        .then(() => {
          this.mesasListSelected.splice(mesaSelected, 1)
          this.getEtiquetas()
        })
        .catch(err => {
          console.log(err)
          mesa.estado = 'En proceso'
        });
    } else {
      if(mesa.estado == 'Disponible') {
        mesa.estado = 'En proceso'
        mesa.expireDate = this.expireDate
        this.mesas.update(mesa.key, mesa)
        .then(() => {
          this.mesasListSelected.push(mesa)
          this.getEtiquetas()
        })
        .catch(err => {
          console.log(err)
          mesa.estado = 'Disponible'
        });
      } else {
        
      }
    }
  }

  searchMesaSelected(numMesaSearch: number) {
    const mesaSelected = this.mesasListSelected.findIndex(({ numMesa }) => numMesa == numMesaSearch);
    if(mesaSelected >= 0){
      return true
    } else {
      return false
    }
  }

  getEtiquetas() {
    var mesasSeleccionadas = ""
    var totalPagar = 0
    this.mesasListSelected.forEach(function (mesa) {
      if(mesasSeleccionadas == "") {
        mesasSeleccionadas = ""+mesa.numMesa
      } else{
        mesasSeleccionadas += "/"+mesa.numMesa
      }
      totalPagar = totalPagar + mesa.precio
    });
    this.mesasSeleccionadas = mesasSeleccionadas
    this.totalPagar = (totalPagar).toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })
  }

  retrieveMesas(): void {
    //Julio Ocampo - me falta revisar si el comercio tiene disponible servicio de mesas
    this.mesas.getMesasByComercio("1").snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() })
        )
      )
    ).subscribe(data => {
      this.mesasList =  data.sort(function (first, second) {
        return first.numMesa - second.numMesa;
      });
      console.log(this.mesasList)
      this.mesasList.forEach( (mesa) => {
        if(mesa.estado == 'En proceso') {
          if(mesa.expireDate != null && mesa.expireDate != undefined) {
            var now = new Date().getTime()
            if(now > mesa.expireDate){
              mesa.estado = 'Disponible'
              mesa.expireDate = null
              this.mesas.update(mesa.key, mesa)
              .then(() => {
                
              })
              .catch(err => {
                console.log(err)
              });
            }
          }
        }
      });
    });
  }


  next(){
    this.payment.amount = +this.totalPagar;
    this.payment.description = this.mesasSeleccionadas;
    this.paymentService.setCurrentPayment(this.payment);
    localStorage.setItem('mesasSelected', JSON.stringify(this.mesasListSelected))
    this.router.navigate(['recharge/credit-card']);
  }

  ngOnDestroy(): void {
    this.paymentServiceSubscription.unsubscribe();
  }
}
