import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Error } from 'src/app/model/error.model';
import { MesaModel } from 'src/app/model/mesa.model';
import { Notify, NotifyData } from 'src/app/model/notify';
import { Payment } from 'src/app/model/payment.model';
import { Payment3D } from 'src/app/model/payment3d.model';
import { Transaction } from 'src/app/model/transaction.model';
import { IpServiceService } from 'src/app/services/ip-service.service';
import { MesasFirebaseService } from 'src/app/services/mesas-fb.service';
import { NotifyService } from 'src/app/services/notify.service';
import { TagsService } from 'src/app/services/tags.service';
import { PaymentService } from 'src/app/store/payment.service'; 
import { environment } from 'src/environments/environment';

declare var SrPago: any;

@Component({
  selector: 'app-srpago',
  templateUrl: './srpago.component.html',
  styleUrls: ['./srpago.component.css']
})
export class SrpagoComponent implements OnInit {
  formGroup: FormGroup;

  ipAddress: string = '';
  message: string = '';
  isAmex: boolean = false;
  maxlength: number = 3;
  cvvStr: String = '';
  payment: Payment3D = new Payment3D();
  withErrorPromotions=false;
  messagePromotions='';

  promotions:any=[]
  promotion:any;
  hasPromotions:boolean=false;
  loadPromotionsEnabled:boolean=true;
  loadingPromotions:boolean=true;
  isGift:boolean =false;

  submitted = false;
  withError = false;

  apiKey = environment.apiKey;
  is3DSecureProduction = environment.secureModeProd;
  paymentSesion: Payment;
  transaction:Transaction;
  private paymentServiceSubscription: Subscription | undefined;
  private transactionServiceSubscription: Subscription | undefined;
  errors:Error[];
  private errorServiceSubscription: Subscription | undefined;
  constructor(private paymentService: PaymentService,
    private ipService: IpServiceService,
    private tagService: TagsService,
    private notifyService: NotifyService,
    private router: Router,
    private formBuilder: FormBuilder,
    private mesasFirebaseService: MesasFirebaseService
  ) {}


  ngOnInit() {
    this.createForm();
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        this.paymentSesion = currentPayment;
        this.verificaCVV();
      }
    );
    this.transactionServiceSubscription = this.paymentService.currentTransaction.subscribe(
      currentTransaction => {
        this.transaction = currentTransaction;
      }
    );

    this.errorServiceSubscription = this.paymentService.currentError.subscribe(
      currentError => {
        this.errors = currentError;
      }
    );
    this.errors=[];
    this.paymentService.setCurrentError(this.errors)
  }

  

  verificaCVV() {
    this.message = '';
    this.withError = false;
    let cardNumber = String(this.paymentSesion.cardNumber)
    let firstDigit = cardNumber.substring(0, 1);
    if (firstDigit == '3') {
      this.isAmex = true;
      this.maxlength = 4;
    }
     
  }

  onSubmit() {
    this.submitted = true;
    console.log("Validating Form")
    this.validForm();
    this.cvvStr= new String(this.formGroup.controls['cvv'].value);
    console.log(this.cvvStr)
    if (this.isAmex && this.cvvStr.length != 4) {
      console.log("if (this.isAmex && this.cvvStr.length != 4)")
      this.message = '<ul><li>Por favor ingrese 4 dígitos</li></ul>';
      this.submitted = false;
      this.withError = true;
    } else if (!this.isAmex && this.cvvStr.length != 3) {
      console.log("if (this.isAmex && this.cvvStr.length != 3)")
      this.message = '<ul><li>Por favor ingrese 3 dígitos</li></ul>';
      this.submitted = false;
      this.withError = true;
    } else if (this.withError == false) {
      console.log("this.withError == false")
      //this.paymentSesion.cvv= this.cvvStr;
      this.getIP();
      //this.sendPaymentProcessor();
    }
  }

  validForm() {
    this.withError = false;
    let amount: any = this.paymentSesion.amount;
    this.payment.name = this.paymentSesion.userName;
    this.payment.email = this.paymentSesion.userEmail; 
    this.payment.phone = this.paymentSesion.userPhone;
    this.payment.street = this.paymentSesion.street;
    this.payment.city =this.paymentSesion.city;
    this.payment.zipcode =this.paymentSesion.userCP
    this.payment.town = this.paymentSesion.town;//"town city"
    this.payment.number =this.paymentSesion.cardNumber
    let monthindex = this.paymentSesion.expireDate.indexOf("/");
    this.payment.expiry_month =this.paymentSesion.expireDate.substring(0, monthindex);
    this.payment.expiry_year =this.paymentSesion.expireDate.substring(monthindex+1, this.paymentSesion.expireDate.length);
    this.payment.amount = Number(Math.round(amount * 100) / 100);
    this.payment.token = this.transaction.token;
    this.payment.idTransaction =this.transaction.idTransaction
    this.message = '';
    this.isGift=false;

    if (amount <= 0) {
      this.message += '<li>Amount is required </li>';
    }
    if (this.payment.name.trim() == '') {
      this.message += '<li>Name  is required </li>';
    }

    if (this.payment.email.trim() == '') {
      this.message += '<li>Email is required </li>';
    }

    if (!this.payment.phone) {
      this.message += '<li>Phone is required </li>';
    }
    if (this.payment.street.trim() == '') {
      this.message += '<li>Street is required </li>';
    }
    if (this.payment.city.trim() == '') {
      this.message += '<li>City is required</li>';
    }
    if (!this.payment.zipcode) {
      this.message += '<li>Zipcode is required </li>';
    }
    if (this.payment.town.trim() == '') {
      this.message += '<li>Town is required </li>';
    }
    if (!this.payment.number) {
      this.message += '<li>Card number is required </li>';
    }

    if (!this.payment.expiry_month) {
      this.message += '<li>Expiry month is required </li>';
    }

    if (!this.payment.expiry_year) {
      this.message += '<li>Expiry year is required </li>';
    }
/*
    if (this.payment.token.trim() == '') {
      this.message += '<li>Token is required </li>';
    }

    if (this.payment.idTransaction.trim() == '') {
      this.message += '<li>Id transaction is required </li>';
    }
*/
    /*if ((this.promotion<=0 || this.promotion == null) && this.promotions.length>0) {
      this.message += '<li>Select a valid promotion </li>';
    }*/
    if (this.message != '') {
      this.withError = true;
      this.message = '<ul>' + this.message + '</ul>';
    }

    return this.withError;
  }

  sendPaymentProcessor(){
    //console.log(this.paymentSesion);
    //console.log(this.paymentSesion.isFlowKeeppay);
    this.tagService.payment(this.paymentSesion, this.paymentSesion.isFlowKeeppay)
      .subscribe((data: any) => {
        this.submitted =false;
        if(data.errors !=null && data.errors.length>0){
          this.goError(JSON.stringify(data.errors), '500');
        }
        this.router.navigate(['response/success']);
        //this.transaction.idTransaction = data.result.idTransaction
        //this.transaction.processor = data.result.processor
        //this.transaction.token = data.result.token;
        
        /*if ("SRPAGO" == this.transaction.processor) {
          this.paymentService.setCurrentTransaction(this.transaction);
          this.router.navigate(['3dsecure/srPago']);
        } else if ("AMEX" == this.transaction.processor) {
          this.transaction.idOrder=data.result.idOrder
          this.transaction.succesIndicator=data.result.succesIndicator
          this.paymentService.setCurrentTransaction(this.transaction);
          this.router.navigate(['3dsecure/amex']);
        }else{
          this.goError('Hubo un error con el procesador de pagos', '500');
        }*/

      },
        (error) => {
          //console.log('RESPONSE ERROR XXXXX ' + JSON.stringify(error))
          console.error(JSON.stringify(error))
          this.goError('Ocurrió un error al procesar el pago', '500');
          this.router.navigate(['response/error']);
        },
        () => {
          this.submitted = false;
        });
      
  }

  sendData() {
    let msi = this.promotion == undefined || this.promotion ==null?0:this.promotion
    this.submitted = true;
    var data = {
      id: this.payment.token,
      amount: this.payment.amount,
      installments:msi.toString(),
      customer: {
        name: this.payment.name,
        email: this.payment.email,
        phone: this.payment.phone,
        address: {
          street: this.payment.street,
          city: this.payment.city,
          zipcode: this.payment.zipcode,
          town: this.payment.town,
        },
        ip: this.ipAddress,
      },
      card: {
        owner: this.payment.name,
        number: this.payment.number,
        expiry_month: String(this.payment.expiry_month,).padStart(2, '0'),
        expiry_year: this.payment.expiry_year,
        cvv: this.cvvStr + '',
      },
    };
    
    if(msi==0){
      delete data['installments']
      let jsonString = JSON.stringify(data);
      data = JSON.parse(jsonString)
    }
    console.log(data);
    this.sendSrPago(data);
  }

  statusSRPAGO=false;
  sendSrPago(data: any) {
    this.submitted = true;
    console.log(SrPago);
    SrPago.apiKey = this.apiKey;
    SrPago.isProduction = this.is3DSecureProduction;
    SrPago.Threeds.Logger.setLevel(SrPago.Threeds.Logger.DEBUG);
    console.log('sendSrPago sending SR PAGO')
    SrPago.Threeds.pay(data)
      .then((response: any) => {
        console.log('SrPago.Threeds.pay.then ==> ');
        this.statusSRPAGO = true;
        this.sendNotify(
          this.payment.token,
          this.payment.idTransaction,
          response, 'response/success'
        );
      })
      .catch((error: any) => {
        this.statusSRPAGO = false;
        console.log('SrPago.Threeds.pay.catch ==> ');
        this.goError('Payment has been declined', '500');
        this.sendNotify(this.payment.token, this.payment.idTransaction, error,
          'response/error');
        
      });
  }


  goError(error:string, code:string){
    let errorMessage:Error[]=[];
      errorMessage.push(new Error(error, code))
      this.paymentService.setCurrentError(errorMessage)
      //this.router.navigate(['response/error']);
  }
  
  isValidForm(){
    return this.validForm() || this.loadingPromotions;
  }

  getIP() {
    //this.sendData();

    this.ipService.getIPAddress().subscribe(
      (res: any) => {
        this.ipAddress = res.ip;
      },
      (error) => {
        this.ipAddress = '0.0.0.0';
      },
      () =>{
        this.sendData();
      }
    );
  }

  sendNotify(token: string, idTransaction: string, response: any, req_response:string) {
    console.log('send Notify');
    let notify_request = this.getPayload(token, idTransaction, response);
    console.log(JSON.stringify(notify_request));
    this.notifyService
      .sendingNotify(notify_request,this.isGift, this.paymentSesion.isFlowKeeppay)
      .subscribe((data) => {
        if(this.paymentSesion.isEquestre==1){
          this.releasesMesas();
        }
        
        localStorage.setItem("idTransaction", idTransaction)

        this.submitted = false;
        this.router.navigate([req_response]);
      },
      (error)=>{
        if(this.paymentSesion.isEquestre==1){
          this.releasesMesas();
        }        
        this.goError('Payment has been declined', '500');
        console.log('RESPONSE ERROR XXXXX '+JSON.stringify(error))
        this.submitted = false;
        this.router.navigate([req_response]);

      },
      () => {
        
      });
  }

  
  releasesMesas(){
    let mesasListSelected: MesaModel[]=[];
    mesasListSelected =  JSON.parse(localStorage.getItem('mesasSelected'));
    let status = this.statusSRPAGO ==true?'Vendida':'Disponible'
    console.log('releases mesas==>'+ JSON.stringify(mesasListSelected))
    for(var mesa of mesasListSelected){
      mesa.estado=status;
      this.mesasFirebaseService.update(mesa.key, mesa);
    }
    localStorage.removeItem('mesasSelected')
  }

  getPayload(token: string, idTransaction: string, response: any): Notify {
    let notifyData: NotifyData = new NotifyData(token, idTransaction, response);
    let notify: Notify = new Notify(notifyData);
    return notify;
  }

  createForm() {
    let numberReguex:RegExp = /[0-9]$/
    this.formGroup = this.formBuilder.group({
     'cvv': [null, [Validators.required, Validators.pattern(numberReguex)]],
      'validate': ''
    });
  }

  ngOnDestroy(): void {
    this.paymentServiceSubscription.unsubscribe();
    this.transactionServiceSubscription.unsubscribe();
  }

}
