export class Payment3D {
    public name: string='';
    public email: string='';
    public phone: string='';
    public street: string='';
    public city: string='';
    public zipcode: string='';
    public town: string='';
    public ip: string='';
    public number: string='';
    public expiry_month: string='';
    public expiry_year: string='';
    public cvv: string='';
    public amount: number=0;
    public token: string='';
    public idTransaction: string='';
  
    constructor() {
  
    }
  }
  