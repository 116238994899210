<div align="center">
    <mat-card *ngIf="isTagValid" fxLayoutAlign="center" class="content_home_noRound" >
        <mat-card-header >
            <mat-card-title>
                <mat-grid-list cols="1" rowHeight="120" style="padding-top: 65px;">
                    <mat-grid-tile>
                        
                        <div align="center" >
                            <br>
                            <img src="{{imageBase64}}" style="width: 180px;height: 95px;">
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list cols="1" rowHeight="100">
                    <mat-grid-tile>
                        <label class="h1Extended" style="color: black;">{{nombreComercio}}</label>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-card-title>
        </mat-card-header>
       
        <mat-card-content >
            <mat-grid-list cols="1" rowHeight="185">
                <mat-grid-tile>
                    <button mat-raised-button color="primary" type="submit"  style="width: 80%;"
                        (click)="goRecharge()"> {{subtitle}}
                    </button>
                </mat-grid-tile>
                <mat-grid-tile *ngIf="this.payment.isEquestre" >
                    <button mat-raised-button color="primary" type="submit" style="width: 80%;"
                        (click)="goMesas()">Mesas  </button>
                </mat-grid-tile>
            </mat-grid-list>
         
        </mat-card-content>

    </mat-card>
    <div style=" background-color: rgb(27, 124, 252) !important; margin-top: -40px; height: 160px; padding-left: -20px; padding-right: -20px;"  class="content_home_noRound"  *ngIf="isFlowKeeppay">
        <p class="home-carrusel">La forma más fácil, segura y flexible<br> de pagar y que te paguen <br>
            <a href="https://prod-services.2keepcash.com/keeppay-mf/signIn">¡Regístrate aquí!</a><br>
            </p>
    </div>

<div style="margin-top: 30px;" *ngIf="isTagValid">
    <div align="center" style="width: 100%; display: inline-block;">
        <p>Operado por:</p>
    </div>
    <div style="width: 100%">
        <img src="assets/images/kc_logo.svg">
    </div>
</div>

    <div *ngIf="!isTagValid">
        <mat-card>
            <div align="center">
                <form class="form">
                    <mat-grid-list cols="1" rowHeight="550">
                        <mat-grid-tile>
                            <img srcset="assets/images/conveyor_1_v1.gif 1200w" sizes="(max-height: 400px) 280px,
                                600px" src="assets/images/conveyor_1_v1.gif" />
                        </mat-grid-tile>
                    </mat-grid-list>
                </form>
            </div>
        </mat-card>

    </div>