import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Payment } from 'src/app/model/payment.model';
import { PaymentService } from 'src/app/store/payment.service'; 

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {
  private paymentServiceSubscription: Subscription | undefined;
  image:string;
  payment: Payment;

  constructor(private paymentService: PaymentService) {
    this.image = 'frame.svg'

   }

  ngOnInit(): void {
    this.image = 'frame.svg'
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        this.payment = currentPayment;
        console.log(this.payment.image + "IMG")
        if(this.payment.image === undefined) {
          this.image = 'frame.svg'
        }else if(this.payment.image !='' || this.payment.image !=null){
          this.image = this.payment.image;
        }
      }
    );

  }

  ngOnDestroy(): void {
    this.paymentServiceSubscription.unsubscribe();
  }
}
