<div id="embed-target"> </div>

<button type="button" id="detailTransactionBtn" value="getDetail" (click)="getOrder()"
    style="visibility: hidden;">getDetail</button>
<br>

<span id="succesIndicator_span" style="display:none;"></span>

<button type="button" id="setCheckout" value="initCheckout" (click)="initCheckout()"
    style="visibility: hidden;">initCheckout</button>

    <div style="margin-top: 30px;">
        <mat-grid-list cols="2" rowHeight="70px" style="top: 20;">
            <mat-grid-tile><img src="assets/images/kc_logo.svg"></mat-grid-tile>
            <mat-grid-tile><img src="assets/images/smart_key.svg"></mat-grid-tile>
        </mat-grid-list>
    </div>