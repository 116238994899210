<mat-grid-list cols="1" rowHeight="30px">
    <mat-grid-tile rowspan="4">
        <img width="80" height="80" src="assets/images/keeppay.png">
    </mat-grid-tile>
    <mat-grid-tile rowspan="3">
        <label class="h2Extended">Elige la opción deseada</label>
    </mat-grid-tile>
    <mat-grid-tile rowspan="3">
        <button mat-raised-button="" color="primary" type="button" (click)="goMesas()"
            class="mat-focus-indicator button mat-raised-button mat-button-base mat-primary">
            <span class="mat-button-wrapper"> Mesas </span>
            <span matripple="" class="mat-ripple mat-button-ripple"></span>
            <span class="mat-button-focus-overlay"></span>
        </button>
    </mat-grid-tile>
</mat-grid-list>