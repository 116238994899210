import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Payment } from 'src/app/model/payment.model';
import { MesasFirebaseService } from 'src/app/services/mesas-fb.service';
import { TagsService } from 'src/app/services/tags.service';
import { PaymentService } from 'src/app/store/payment.service';
import { Error } from 'src/app/model/error.model';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})
export class ReservationsComponent implements OnInit {
  idTag: string;
  payment: Payment;
  isLoading: boolean;
  isTagValid: boolean;
  title: string;
  image: string;
  isFlowKeeppay: boolean;
  subtitle: string;
  nombreComercio: string;
  isGoMesas: boolean;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private tagService: TagsService,
    private paymentService: PaymentService,
    private mesas: MesasFirebaseService) {
    
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.isTagValid = false;
    this.payment = new Payment();
    this.payment.image = this.image;
    this.payment.idEvent = this.route.snapshot.paramMap.get('id');
    console.log(isNaN(Number(this.payment.idEvent)))
    this.searchComercio();
    this.isGoMesas = false;
  }

  searchComercio() {
    this.tagService.getUser(+this.payment.idEvent).subscribe({
      next: (data => {
        this.nombreComercio = data.empresa;
        this.payment.comercio = data.empresa;
        this.payment.isEquestre = data.isEquestre;
        this.payment.clabe = data.clabe;
        this.isFlowKeeppay = true;
        this.payment.isFlowKeeppay = this.isFlowKeeppay;
        this.isTagValid = true;
        this.payment.idTag = 0 + "";

        this.paymentService.setCurrentPayment(this.payment);
        this.subtitle = 'Paga aquí'
        //this.retrieveMesas();
      }),
      error: (error => {
        this.goError('Ocurrió un error al recuperar el comercio', '005');
      }),
      complete: (() => console.log("complete"))
    });
  }

  clearErrorMessage() {
    let errorMessage: Error[] = [];
    this.paymentService.setCurrentError(errorMessage)
  }

  goError(error: string, code: string) {
    let errorMessage: Error[] = [];
    errorMessage.push(new Error(error, code))
    this.paymentService.setCurrentError(errorMessage)
    this.router.navigate(['response/error']);
  }

  goMesas(navToMesas: boolean) {
    this.isGoMesas = navToMesas
  }

}
