import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Commission } from '../model/commission.model';
import { Error } from '../model/error.model';
import { Payment } from '../model/payment.model';
import { Transaction } from '../model/transaction.model';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    private currentPaymentSubject: BehaviorSubject<Payment> = new BehaviorSubject({} as Payment);
    public readonly currentPayment: Observable<Payment> = this.currentPaymentSubject.asObservable();


    private currentTransactionSubject: BehaviorSubject<Transaction> = new BehaviorSubject({} as Transaction);
    public readonly currentTransaction: Observable<Transaction> = this.currentTransactionSubject.asObservable();

    private currentErrorSubject: BehaviorSubject<Error[]> = new BehaviorSubject({} as Error[]);
    public readonly currentError: Observable<Error[]> = this.currentErrorSubject.asObservable();

    private currentCommisionSubject: BehaviorSubject<Commission> = new BehaviorSubject({} as Commission);
    public readonly currentCommision: Observable<Commission> = this.currentCommisionSubject.asObservable();

    constructor() { }

    setCurrentPayment(currentPayment: Payment): void {
        this.currentPaymentSubject.next(currentPayment);
    }

    setCurrentTransaction(currentTransaction: Transaction): void {
        this.currentTransactionSubject.next(currentTransaction);
    }

    setCurrentError(currentError: Error[]): void {
        this.currentErrorSubject.next(currentError);
    }

    setCurrentCommission(commision: Commission): void {
        this.currentCommisionSubject.next(commision);
    }
}