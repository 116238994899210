import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { Payment } from '../model/payment.model';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(environment.authUser + ':' + environment.authPass)

  }),
};

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  private handleError: HandleError;


  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler,
    @Inject('BASE_URL') private baseURL: string,
    @Inject('BRAZALETES_BASE_URL') private brazaletesBaseURL: string
    ) {
    this.handleError = httpErrorHandler.createHandleError('BrazaletesServices');
  }


  // URL to web API
  getBalance(idEvent: string, barcodeUid: string, isFlowKeeppay:boolean): Observable<any> {
    console.log("from service")
    let baseTemp = this.baseURL

    let restBaseUrl = `${baseTemp}tags/balance/get?idEvent=${idEvent}&barcodeUid=${barcodeUid}`
    return this.http
      .get<any>(restBaseUrl, httpOptions)
      .pipe(catchError(this.handleError('getTransactionDetail-->')));
  }

  getEventDetail(idEvent: string, isFlowKeeppay:boolean): Observable<any> {
    console.log("from service")
    let baseTemp = this.baseURL;///isFlowKeeppay?this.baseKeeppay:this.baseTagUrlParam;

    let restBaseUrl = `${baseTemp}tags/event/${idEvent}`
    return this.http
      .get<any>(restBaseUrl, httpOptions)
      .pipe(catchError(this.handleError('getTransactionDetail-->')));
  }

  getDetailTag(idEvent: string, idTag: string, isFlowKeeppay:boolean): Observable<any> {
    console.log("from service")
    //tags/detail?idEvent=7DAl0v&barcodeUid=B5633BD1
    let baseTemp = this.baseURL;//isFlowKeeppay?this.baseKeeppay:this.baseTagUrlParam;

    let restBaseUrl = `${baseTemp}tags/detail?idEvent=${idEvent}&barcodeUid=${idTag}`
    return this.http
      .get<any>(restBaseUrl, httpOptions)
      .pipe(catchError(this.handleError('getTransactionDetail-->')));
  }


  payment(payment: Payment,isFlowKeeppay:boolean): Observable<any> {
    var clone = Object.assign({}, payment);
    delete clone['isFlowKeeppay'];
    delete clone['image'];
    delete clone['isEquestre'];
    delete clone['transactionType'];
    
    //if(isFlowKeeppay){
      //delete clone['clabe'];
    //}

    console.log("from service")
    let baseTemp = this.brazaletesBaseURL;//isFlowKeeppay?this.baseKeeppay:this.baseApi;

    let restBaseUrl = `${baseTemp}processor/payment`
    return this.http
    .post<Payment>(restBaseUrl, clone, httpOptions)
    .pipe(catchError(this.handleError('getTransactionDetail-->')));
  }


  //http://dev-brazaletes-alb-1192189913.us-west-2.elb.amazonaws.com:8008/movements/tag/5C5CEBBB
  getMovementsTag(idTag: string,isFlowKeeppay:boolean): Observable<any> {
    console.log("from service")
    //tags/detail?idEvent=7DAl0v&barcodeUid=B5633BD1
    let baseTemp = this.baseURL;//isFlowKeeppay?this.baseKeeppay:this.baseMovementsUrlParam;

    let restBaseUrl = `${baseTemp}movements/tag/${idTag}`
    return this.http
      .get<any>(restBaseUrl, httpOptions)
      .pipe(catchError(this.handleError('getTransactionDetail-->')));
  }

  getUser(id:number): Observable<any> {
    console.log("from service")
    let urlBaseFinal = this.baseURL + `keeppay/comercio/search/${id}`
    return this.http
      .get<any>(urlBaseFinal, httpOptions);
  }
}
