import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { FolioComponent } from './pages/folio/folio.component';
import { RecargaAmountComponent } from './pages/recarga-amount/recarga-amount.component';
import { CreditCardComponent } from './pages/credit-card/credit-card.component';
import { ConfirmaRecargaComponent } from './pages/confirma-recarga/confirma-recarga.component';
import { SrpagoComponent } from './pages/3dsecure/srpago/srpago.component';
import { AmexComponent } from './pages/3dsecure/amex/amex.component';
import { SuccessComponent } from './pages/success/success.component';
import { ErrorComponent } from './pages/error/error.component';
import { ReservationsComponent } from './pages/reservations/reservations.component';
import { OpenpayComponent } from './pages/3dsecure/openpay/openpay.component';
import { OpenpayRedirectComponent } from './pages/3dsecure/openpay-redirect/openpay-redirect.component';

const routes: Routes = [
  {
    path: ':id',
    component: HomeComponent,
  },
  {
    path: 'folio/paga',
    component: FolioComponent,
  },
  {
    path: 'recharge/amount',
    component: RecargaAmountComponent,
  },
  {
    path: 'recharge/credit-card',
    component: CreditCardComponent,
  },
  {
    path: 'recharge/confirm',
    component: ConfirmaRecargaComponent,
  },
  {
    path: '3dsecure/srPago',
    component: SrpagoComponent,
  },
  {
    path: '3dsecure/amex',
    component: AmexComponent,
  },
  {
    path: 'response/success',
    component: SuccessComponent,
  },
  {
    path: 'response/error',
    component: ErrorComponent,
  },{
    path: 'payment/openpay',
    component: OpenpayComponent,
  },
  {
    path: 'payment/redirect',
    component: OpenpayRedirectComponent,
  },
  {
    path: 'reservations/:id',
    component: ReservationsComponent
  },
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
