import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Error } from 'src/app/model/error.model';
import { Payment } from 'src/app/model/payment.model';
import { MesasFirebaseService } from 'src/app/services/mesas-fb.service';
import { TagsService } from 'src/app/services/tags.service';
import { PaymentService } from 'src/app/store/payment.service';
import { map } from 'rxjs/operators';
import { MesaModel } from 'src/app/model/mesa.model';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  idTag: string;
  payment: Payment;
  isLoading: boolean;
  isTagValid: boolean;
  title: string;
  image: string;
  isFlowKeeppay: boolean;
  subtitle: string;
  nombreComercio: string;
  mesasList:MesaModel[]
  is111:boolean=false;
  imageBase64:string;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private tagService: TagsService,
    private paymentService: PaymentService,
    private mesas: MesasFirebaseService) { }

  ngOnInit() {
    this.subtitle = "";
    this.image = 'frame.svg'
    this.isLoading = false;
    this.isTagValid = false;
    this.payment = new Payment();
    this.payment.image = this.image;
    this.payment.idEvent = this.route.snapshot.paramMap.get('id');
    console.log(isNaN(Number(this.payment.idEvent)))
    this.searchComercio();
    this.subtitle = 'Paga aquí';
  }

  searchComercio() {
    this.tagService.getUser(+this.payment.idEvent).subscribe({
      next: (data => {

        if(+this.payment.idEvent == 111){
          this.is111 =true;
        }else{
          this.is111=false;
        }
        this.nombreComercio = data.empresa;
        this.payment.comercio = data.empresa;
        this.payment.isEquestre = data.isEquestre;
        this.payment.clabe = data.clabe;
        this.payment.transactionType=data.transactionType;
        this.isFlowKeeppay = true;
        this.payment.isFlowKeeppay = this.isFlowKeeppay;
        this.isTagValid = true;
        this.payment.idTag = 0 + "";
        if(data.image===undefined){
          this.imageBase64 = this.image


        }else{
          this.imageBase64 = data.image

        }
        this.paymentService.setCurrentPayment(this.payment);
        this.subtitle = 'Paga aquí'
        this.retrieveMesas();
      }),
      error: (error => {
        this.goError('Ocurrió un error al recuperar el comercio', '005');
      }),
      complete: (() => console.log("complete"))
    });
  }

  flujoKeeppayWithVerification() {
    this.tagService.getEventDetail(this.payment.idEvent, this.payment.isFlowKeeppay)
      .subscribe((response: any) => {
        if (response.data != null && response.data.length > 0) {
          let filterByIdtag = response.data.filter((data: any) => {
            return data.uuid === this.payment.idEvent;
          });
          if (filterByIdtag.length > 0) {
            const startDate = new Date(filterByIdtag[0].startDate);
            const endDate = new Date(filterByIdtag[0].endDate);
            const nowDate = new Date();
            if (!(nowDate >= startDate && nowDate <= endDate)) {
              this.goError('El tag ha expirado', '002');

            } else {
              this.payment.image = this.image;
              this.payment.idTag = 0 + "";
              this.clearErrorMessage();
              this.paymentService.setCurrentPayment(this.payment);
              this.isTagValid = true;
              this.title = filterByIdtag[0].name
            }
          }
        } else {
          this.goError('Ocurrió un error al recuperar el tag', '003');

        }
      },
        (error) => {
          console.log('RESPONSE ERROR XXXXX ' + JSON.stringify(error))
          console.error(JSON.stringify(error));
          this.goError('Tag no válido', '001');
        },
        () => {


        });
  }

  clearErrorMessage() {
    let errorMessage: Error[] = [];
    this.paymentService.setCurrentError(errorMessage)
  }

  goError(error: string, code: string) {
    let errorMessage: Error[] = [];
    errorMessage.push(new Error(error, code))
    this.paymentService.setCurrentError(errorMessage)
    this.router.navigate(['response/error']);
  }

  goRecharge() {
    this.isLoading = true;
    let route = 'recharge/amount'
    this.router.navigate([route]);
  }

  goBalance() {
    let route = 'balance' 
    this.router.navigate([route]);
  }

  goMovements() {
    let route = 'movements' 
    this.router.navigate([route]);

  }
  goMesas() {
    let route = 'reservations/'+this.payment.idEvent ;
    this.router.navigate([route]);

  }


  retrieveMesas(): void {
    this.mesas.getMesasByComercio(this.payment.idEvent).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() })
        )
      )
    ).subscribe(data => {
      this.mesasList =  data.sort(function (first, second) {
        return first.numMesa - second.numMesa;
      });
      console.log(this.mesasList)
    });
  }

}
