import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
import { Commission } from '../model/commission.model';
@Injectable({
  providedIn: 'root'
})
export class CommissionKeeppayService {
  private dbPath = '/prod_commissions_keeppay';
  commissionsRef: AngularFireList<Commission> = null;
  
  
  constructor(private db: AngularFireDatabase) {
    if(environment.production){
      this.commissionsRef = db.list(this.dbPath);
    }else{
      this.commissionsRef = db.list("dev_commissions_keeppay");

    }
    
  }
  
  getAll(): AngularFireList<Commission> {
    return this.commissionsRef;
  }
  
}