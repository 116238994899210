import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Commission } from 'src/app/model/commission.model';
import { Error } from 'src/app/model/error.model';
import { Payment } from 'src/app/model/payment.model';
import { Transaction } from 'src/app/model/transaction.model';
import { TagsService } from 'src/app/services/tags.service';
import { PaymentService } from 'src/app/store/payment.service'; 

@Component({
  selector: 'app-confirma-recarga',
  templateUrl: './confirma-recarga.component.html',
  styleUrls: ['./confirma-recarga.component.css']
})
export class ConfirmaRecargaComponent implements OnInit {
  payment: Payment;
  transaction: Transaction;
  cardHide: string;
  isLoading: boolean;
  isFlowKeeppay: boolean;
  comision = "Uso plataforma";
  Monto_a_recargar = "Monto a recargar"
  Uso_de_plataforma = "Seguridad & antifraude"
  titulo = "Confirma tu pago"
  beneficiariotxt = "Beneficiario"
  errors: Error[];
  private errorServiceSubscription: Subscription | undefined;
  private paymentServiceSubscription: Subscription | undefined;
  private transactionServiceSubscription: Subscription | undefined;
  private commissionServiceSubscription: Subscription | undefined;
  commission: Commission;
  isLoadingCommissions: boolean = false;
  conceptoText:string;
  constructor(private paymentService: PaymentService,
    private tagService: TagsService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.isLoading = false;
    this.isLoadingCommissions = false;
    this.conceptoText='Concepto:'
    this.paymentServiceSubscription = this.paymentService.currentPayment.subscribe(
      currentPayment => {
        this.payment = currentPayment;
        console.log(this.payment);
        this.isFlowKeeppay = this.payment.isFlowKeeppay;
        if (this.isFlowKeeppay) {
          this.comision = "Uso plataforma"
          this.Monto_a_recargar = "Monto a pagar";
          this.Uso_de_plataforma = "Seguridad & antifraude"
          this.titulo = "Confirma tu pago"
          this.beneficiariotxt = "Destinatario"

        }
        if(this.payment.transactionType){
          this.conceptoText = this.payment.transactionType
        }
        this.loadCommisionService();
        let sizeCardNumber = String(this.payment.cardNumber).length;
        this.cardHide = this.payment.card + ' ' + this.payment.cardNumber.substring(sizeCardNumber - 4, sizeCardNumber);

      }
    );
    this.transactionServiceSubscription = this.paymentService.currentTransaction.subscribe(
      currentTransaction => {
        this.transaction = currentTransaction;

      }
    );



    this.errorServiceSubscription = this.paymentService.currentError.subscribe(
      currentError => {
        this.errors = currentError;
      }
    );
    this.errors = [];
    this.paymentService.setCurrentError(this.errors)
  }

  loadCommisionService() {
    this.commissionServiceSubscription = this.paymentService.currentCommision.subscribe(
      currentCommission => {
        console.log(JSON.stringify(currentCommission))
        this.commission = currentCommission;
        this.calculaMonto();
      }
    );
  }

  calculaMonto() {
    if (this.payment.isFlowKeeppay) {
      console.log("comission --> " + this.commission.commission)
      let tempAmount: number = (this.commission.commission / 100) + 1
      console.log("tempAmount --> " + (this.commission.commission / 100) + 1)
      tempAmount = (this.payment.amount * tempAmount) + this.commission.userPlatform;
      console.log("TEMP ===> " + tempAmount);
      console.log("TOTAL ==> " + Math.round((tempAmount + Number.EPSILON) * 100) / 100)
      this.payment.total = Math.round((tempAmount + Number.EPSILON) * 100) / 100
    }
    this.
    
    isLoadingCommissions = true;
  }

  esNumeroTarjetaAMEX(numeroTarjeta: string): boolean {
    // Eliminar espacios en blanco y guiones, si los hay
    const numeroTarjetaLimpio = numeroTarjeta.replace(/\s|-/g, '');
  
    // Patrón de número de tarjeta AMEX
    const amexPattern = /^3[47]\d{13}$/;
  
    // Comprobar si el número coincide con el patrón de AMEX
    return amexPattern.test(numeroTarjetaLimpio);
  }

  next() {
    this.isLoading = true;
    //this.goError('Intente nuevamente mas tarde', "300");

    console.log(JSON.stringify(this.payment))
    let flujoSRPago=this.esNumeroTarjetaAMEX(this.payment.cardNumber);

    if(false){
      this.tagService.payment(this.payment, this.payment.isFlowKeeppay)
      .subscribe((data: any) => {

        if (data.errors != null && data.errors.length > 0) {
          this.goError(JSON.stringify(data.errors), '500');
        }

        this.transaction.idTransaction = data.result.idTransaction
        this.transaction.processor = data.result.processor
        this.transaction.token = data.result.token;
        console.log(this.transaction.processor);
        //if ("SRPAGO" == this.transaction.processor) {
          this.paymentService.setCurrentTransaction(this.transaction);
          this.router.navigate(['3dsecure/srPago']);
        /*} else if ("AMEX" == this.transaction.processor) {
          this.transaction.idOrder = data.result.idOrder
          this.transaction.succesIndicator = data.result.succesIndicator
          this.paymentService.setCurrentTransaction(this.transaction);
          this.router.navigate(['3dsecure/amex']);
        } else {
          this.goError('Hubo un error con el procesador de pagos', '500');
        }*/

      },
        (error) => {
          console.log('RESPONSE ERROR XXXXX ' + JSON.stringify(error))
          console.error(JSON.stringify(error))
          this.goError(JSON.stringify(error), '500');

        },
        () => {
          this.isLoading = false;
        });
    }else{
      this.router.navigate(['payment/openpay']);

    }
    


  }

  goError(error: string, code: string) {
    //let errorMessage:Error[]=[];
    this.errors.push(new Error(error, code))
    this.paymentService.setCurrentError(this.errors)
    this.router.navigate(['response/error']);
  }

  ngOnDestroy(): void {
    this.paymentServiceSubscription.unsubscribe();
    this.transactionServiceSubscription.unsubscribe();
    this.errorServiceSubscription.unsubscribe();
    this.commissionServiceSubscription.unsubscribe();
  }


}
