import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { MessageService } from './services/message.service';
import { environment } from 'src/environments/environment';
import { FolioComponent } from './pages/folio/folio.component';
import { RecargaAmountComponent } from './pages/recarga-amount/recarga-amount.component';
import { AppFooterComponent } from './pages/app-footer/app-footer.component';
import { CreditCardComponent } from './pages/credit-card/credit-card.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ConfirmaRecargaComponent } from './pages/confirma-recarga/confirma-recarga.component';
import { SrpagoComponent } from './pages/3dsecure/srpago/srpago.component';
import { AmexComponent } from './pages/3dsecure/amex/amex.component';
import { SuccessComponent } from './pages/success/success.component';
import { ErrorComponent } from './pages/error/error.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { ReservationsMenuComponent } from './components/reservations-menu/reservations-menu.component';
import { ReservationsMesasComponent } from './components/reservations-mesas/reservations-mesas.component';
import { ReservationsComponent } from './pages/reservations/reservations.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CountDownComponent } from './components/count-down/count-down.component';
import { AppHeaderComponent } from './pages/app-header/app-header.component';
import { NavComponentComponent } from './pages/nav-component/nav-component.component';
import { MatFormFieldControl, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { InputPriceFormatModule } from 'angular-input-price-format';
import { OpenpayRedirectComponent } from './pages/3dsecure/openpay-redirect/openpay-redirect.component';
import { OpenpayComponent } from './pages/3dsecure/openpay/openpay.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';


@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    HomeComponent,
    FolioComponent,
    AppFooterComponent,
    RecargaAmountComponent,
    CreditCardComponent,
    ConfirmaRecargaComponent,
    SrpagoComponent,
    AmexComponent,
    SuccessComponent,
    ErrorComponent,
    NavComponentComponent,
    ReservationsMenuComponent,
    ReservationsMesasComponent,
    ReservationsComponent,
    LoadingComponent,
    CountDownComponent,
    OpenpayComponent,
    OpenpayRedirectComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputPriceFormatModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    LayoutModule,ShareButtonsModule,
    ShareIconsModule
  ],
  providers: [HttpErrorHandler,
    MessageService,
    { provide: "BASE_URL", useValue: environment.baseUrl },
    {provide: "BRAZALETES_BASE_URL", useValue: environment.brazaletesBaseURL },
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance:'outline'}},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance:'outline'}}

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }
