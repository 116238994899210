import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Error } from 'src/app/model/error.model';
import { Payment } from 'src/app/model/payment.model';
import { PaymentService } from 'src/app/store/payment.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  errors:Error[];
  private errorServiceSubscription: Subscription | undefined;

  constructor(private paymentService:PaymentService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {
    
    this.errorServiceSubscription = this.paymentService.currentError.subscribe(
      currentError => {
        this.errors = currentError;
      }
    );
  }

  goHome() {
    let json=JSON.parse(localStorage.getItem('payment'))
    var ruta =  json.idEvent
    this.router.navigate(['/' + ruta]);
  }

  ngOnDestroy(): void {
    let errorMessage: Error[] = [];
    this.paymentService.setCurrentError(errorMessage)
    this.errorServiceSubscription.unsubscribe();
  }

}
