export const environment = {

    production: false,
    baseUrl: 'https://prod-services.2keepcash.com/',
    brazaletesBaseURL:'https://brazaletes.2keepcash.com/',
    authUser: 'core-service',
    authPass: 'K33pC4sH@S3cuR3!',
    firebaseConfig: {
      apiKey: "AIzaSyAfMOsS6F4Ujotrw0x4qJWWbCAO0crfwIc",
      authDomain: "keepcash-724e5.firebaseapp.com",
      projectId: "keepcash-724e5",
      storageBucket: "keepcash-724e5.appspot.com",
      messagingSenderId: "274550584989",
      appId: "1:274550584989:web:2baf35563d89b172ab4674",
      measurementId: "G-RDLD359GKN"
    },
  
    secureModeProd: true,
    apiKey: 'pk_live_6216b31f3064dTfK8L',
    //apiKey: 'pk_dev_61fc2547a20957v8gt',
    amexUrl:'http://localhost:8888/',
    amexUsername:'merchant.TEST8482158268',
    amexPassword:'0eeccde1a11df1edf10c53b5f3c86e0f',
    amexMerchant:'TEST8482158268',
    urlOpenPay:'https://dev-external-api.2keepcash.com/chargesOpenpay/',
    shareFileURL:'https://external-api.2keepcash.com/'


};