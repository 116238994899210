<app-header></app-header>
<div align="center" style="padding-bottom: 10px;padding-top: 10px;">
    <label class="confirm-label">{{titulo}}</label></div>
<mat-card>
    <div align="center">
        <form class="form">

        <mat-grid-list cols="2" rowHeight="43px" class="row">
            <mat-grid-tile>
                <mat-label autofocus>Modalidad:</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="secondtitle">Un solo pago</p>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="43px" class="row" *ngIf="!isFlowKeeppay">
            <mat-grid-tile >
                <mat-label>Cuenta:</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="secondtitle">{{payment.account}}</p>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="43px" class="row">
            <mat-grid-tile>
                <mat-label>Tarjeta:</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="secondtitle">{{cardHide}}</p>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="43px" class="row">
            <mat-grid-tile>
                <mat-label>{{beneficiariotxt}}:</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <p *ngIf="!isFlowKeeppay">{{payment.userName}}</p>
                <p *ngIf="isFlowKeeppay">{{payment.comercio}}</p>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="43px" class="row">
            <mat-grid-tile>
                <mat-label>{{conceptoText}}:</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="secondtitle">{{payment.description}}</p>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="43px" class="row">
            <mat-grid-tile>
                <mat-label>{{Monto_a_recargar}}:</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="secondtitle">${{payment.amount}}</p>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="43px" class="row">
            <mat-grid-tile>
                <mat-label>{{comision}}:</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="secondtitle">{{payment.comision}}%</p>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="43px" class="row">
            <mat-grid-tile>
                <mat-label>{{Uso_de_plataforma}}:</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="secondtitle">${{payment.platformAmount}}</p>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="43px" class="row_strong">
            <mat-grid-tile>
                <mat-label>Total a pagar:</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <p>${{ payment.total }}</p>
            </mat-grid-tile>

        </mat-grid-list>

        <div class="form-element" align="center">
            <button mat-raised-button color="primary" type="submit" class="button"
                [disabled]="!isLoadingCommissions"
                (click)="next()">
                Continuar
                    <mat-icon *ngIf="isLoading"><mat-spinner color="accent" diameter="20">
                        </mat-spinner>
                    </mat-icon>
                </button>
            </div>
        </form>
    </div>
</mat-card>

<app-footer></app-footer>