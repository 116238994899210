<div class="fullscreen-div" style="height: 100%;">

  <div class="container_header">


  </div>

  <app-footer></app-footer>
  <div class="container">

    <table align="center" width="100%">


      <tr>
        <td align="center">
          <span class="failed">Pago fallido</span>

        </td>

      </tr>
      <tr>
        <td style="height: 30%;">&nbsp;</td>
      </tr>



    </table>
  </div>

  <table align="center" width="100%">
    <tr>
      <td align="center"><img src="assets/images/failed.png" alt="Imagen" class="bottom-image">
      </td>
    </tr>
  </table>

  <table align="center" width="100%">
    <tr>
      <td align="center">
        <button mat-raised-button color="primary" type="submit" class="button" (click)="goHome()">
          Intentar nuevamente
        </button>
      </td>
    </tr>
  </table>


</div>